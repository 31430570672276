import React, { useState } from 'react';
import './Css/character.css'


import bg from '../../../assets/cyberSkies/bg2.png'
import line5 from '../../../assets/fullScreenLines/lines3.png'


import doraTopName from '../../../assets/cyberSkies/doraTopName.webp'
import novaTopName from '../../../assets/cyberSkies/novaTopName.webp'
import drTopName from '../../../assets/cyberSkies/drTopName.webp'
import jaxTopName from '../../../assets/cyberSkies/jaxTopName.webp'

import doraFull from '../../../assets/cyberSkies/doraFull.webp'
import doraBG from '../../../assets/cyberSkies/doraBG.webp'
import doraStats from '../../../assets/cyberSkies/doraStats.webp'

import novaFull from '../../../assets/cyberSkies/novaFull.webp'
import novaBG from '../../../assets/cyberSkies/novaBG.webp'
import novaStats from '../../../assets/cyberSkies/novaStats.webp'

import drFull from '../../../assets/cyberSkies/drFull.webp'
import drBG from '../../../assets/cyberSkies/drBG.webp'
import drStats from '../../../assets/cyberSkies/drStats.webp'

import jaxFull from '../../../assets/cyberSkies/jaxFull.webp'
import jaxBG from '../../../assets/cyberSkies/jaxBG.webp'
import jaxStats from '../../../assets/cyberSkies/jaxStats.webp'

import cyberSkiesStars from '../../../assets/cyberSkies/stars.png'
import textBackgroundSkies from '../../../assets/cyberSkies/nameBG.png'

import selectArrowSkies from '../../../assets/cyberSkies/selectedArrow.webp'
import doraActiveIcon from '../../../assets/cyberSkies/doraActiveIcon.webp'
import doraIcon from '../../../assets/cyberSkies/doraIcon.webp'

import novaIcon from '../../../assets/cyberSkies/novaIcon.webp'
import novaActiveIcon from '../../../assets/cyberSkies/novaActiveIcon.webp'

import drIcon from '../../../assets/cyberSkies/drIcon.webp'
import drActiveIcon from '../../../assets/cyberSkies/drActiveIcon.webp'

import jaxIcon from '../../../assets/cyberSkies/jaxIcon.webp'
import jaxActiveIcon from '../../../assets/cyberSkies/jaxActiveIcon.webp'




export function Character() {

    const [doraIconState, setDoraIcon] = useState(doraActiveIcon)
    const [novaIconState, setNovaIcon] = useState(novaIcon)
    const [drIconState, setDrIcon] = useState(drIcon)
    const [jaxIconState, setJaxIcon] = useState(jaxIcon)

    const [characterSelected, setCharacterSelected] = useState(doraFull)
    const [characterSelectedBG, setCharacterSelectedBG] = useState(doraBG)
    const [characterSelectedTN, setCharacterSelectedTN] = useState(doraTopName)
    const [characterName, setcharacterName] = useState("DORA")
    const [characterStats, setcharacterStats] = useState(doraStats)

    const [arrowStyle, setArrowStyle] = useState("127.5vw")

    const handleIconClick = (index) => {

        if(index === 1 && doraIconState === doraIcon)
        {
            setDoraIcon(doraActiveIcon)
            setNovaIcon(novaIcon)
            setDrIcon(drIcon)
            setJaxIcon(jaxIcon)
            setCharacterSelected(doraFull)
            setCharacterSelectedBG(doraBG)
            setCharacterSelectedTN(doraTopName)
            setcharacterName("DORA")
            setcharacterStats(doraStats)
            setArrowStyle("127.5vw")
        }
        else if(index === 2 && novaIconState === novaIcon)
        {
            setDoraIcon(doraIcon)
            setNovaIcon(novaActiveIcon)
            setDrIcon(drIcon)
            setJaxIcon(jaxIcon)
            setCharacterSelected(novaFull)
            setCharacterSelectedBG(novaBG)
            setCharacterSelectedTN(novaTopName)
            setcharacterStats(novaStats)
            setcharacterName("NOVA")
            setArrowStyle("132.5vw")
        }
        else if(index === 3 && drIconState === drIcon)
        {
            setDoraIcon(doraIcon)
            setNovaIcon(novaIcon)
            setDrIcon(drActiveIcon)
            setJaxIcon(jaxIcon)
            setCharacterSelected(drFull)
            setCharacterSelectedBG(drBG)
            setCharacterSelectedTN(drTopName)
            setcharacterStats(drStats)
            setcharacterName("DR.AMADEUS")
            setArrowStyle("137.5vw")
        }
        else if(index === 4 && jaxIconState === jaxIcon)
        {
            setDoraIcon(doraIcon)
            setNovaIcon(novaIcon)
            setDrIcon(drIcon)
            setJaxIcon(jaxActiveIcon)
            setCharacterSelected(jaxFull)
            setCharacterSelectedBG(jaxBG)
            setCharacterSelectedTN(jaxTopName)
            setcharacterStats(jaxStats)
            setcharacterName("JAX")
            setArrowStyle("142.5vw")
        }
    };
    return (
        <>
            <img src={bg} alt="bg" className="bgSkies2" />
            <img src={line5} alt="line5" className="line5Skies" />
            <h2 className='mainHeaderSKIESChar'>CHARACTERS</h2>
            <div className="group-containerSKIESChar">
                <div className="rectangle rectangle-1SKIESChar"></div>
                <div className="rectangle rectangle-2SKIESChar"></div>
            </div>


            <img src={cyberSkiesStars} alt="cyberSkiesStars" className="cyberSkiesStars" />
            <img src={textBackgroundSkies} alt="textBackgroundSkies" className="textBackgroundSkies" />
            <img src={characterStats} alt="characterStatsSkies" className="characterStatsSkies" />


            <img src={characterSelectedBG} alt="characterSelectedBG" className="characterSelectedBG" />
            <img src={characterSelectedTN} alt="characterSelectedTN" className="characterSelectedTN" />
            <img src={characterSelected} alt="characterFull" className="characterFull" />

            <h2 alt="characterName" className="characterName">{characterName}</h2>


            <img src={selectArrowSkies} alt="selectArrowSkies" className="selectArrowSkies" style={{ top: arrowStyle }}/>
            <img src={doraIconState} alt="doraIcon" className="doraIcon skiesIcons"  onClick={() => handleIconClick(1)}/>
            <img src={novaIconState} alt="novaIcon" className="novaIcon skiesIcons" onClick={() => handleIconClick(2)}/>
            <img src={drIconState} alt="drIcon" className="drIcon skiesIcons" onClick={() => handleIconClick(3)}/>
            <img src={jaxIconState} alt="jaxIcon" className="jaxIcon skiesIcons" onClick={() => handleIconClick(4)}/>
        </>
    )
};
