import React, { useState } from 'react';
import './Css/customization.css'

import interior1 from '../../../assets/cursedMansion/interior1.webp'
import interior2 from '../../../assets/cursedMansion/interior2.webp'
import interior3 from '../../../assets/cursedMansion/interior3.webp'
import interior4 from '../../../assets/cursedMansion/interior4.webp'
import line9 from '../../../assets/fullScreenLines/lines5.png'

import interior1Icon from '../../../assets/cursedMansion/interior1Icon.png'
import interior2Icon from '../../../assets/cursedMansion/interior2Icon.png'
import interior3Icon from '../../../assets/cursedMansion/interior3Icon.png'
import interior4Icon from '../../../assets/cursedMansion/interior4Icon.png'


export function Customization() {
    const [worldSelected, setWorldSelected] = useState(interior1)
    const [index, setIndex] = useState(0)
    const titleChanged = ["BUILD", "ROOM", "TORTURE CHAMBER", "KITCHEN"]
    const para = [
        "Personalize your sanctum within the Cursed Mansion. Each room is a canvas, and every artifact a choice – craft your refuge, strategize your survival, and prepare for the night's terrors.",
        "Each Room in the mansion tells its own silent story. Personalize this intimate space to reflect your character's journey, a respite from the chaos beyond its walls.",
        "In the Torture Chamber, shadows of torment linger. Its grim apparatuses and darkened corners offer strategic hiding spots or grim reminders of the mansion's history.",
        "The Kitchen, a hearth of bygone revelries, now harbors a sinister air. Amidst the clatter of cutlery and cauldrons, concoct plans and potent defenses."
    ]

    const handleIconClick = (index) => {
        for(var i=1;i<=4; i++){
            document.getElementById(`interior${i}Icon`).classList.remove("selectedInteriorIcon")
        }
        if(index === 1){
            setWorldSelected(interior1)
            setIndex(0)
            document.getElementById("interior1Icon").classList.add("selectedInteriorIcon")
        }
        else if(index === 2){
            setWorldSelected(interior2)
            setIndex(1)
            document.getElementById("interior2Icon").classList.add("selectedInteriorIcon")
        }
        else if(index === 3){
            setWorldSelected(interior3)
            setIndex(2)
            document.getElementById("interior3Icon").classList.add("selectedInteriorIcon")
        }
        else if(index === 4){
            setWorldSelected(interior4)
            setIndex(3)
            document.getElementById("interior4Icon").classList.add("selectedInteriorIcon")
        }
    }
    return (
        <>
            <img src={worldSelected} alt='interior1' className='interior1Cursed'></img>
            <img src={line9} alt='line9' className='line9Cursed'></img>
            <h2 className='customizeHeaderCM'>CUSTOMIZATION</h2>
            <div className="group-containerCustomizeMain">
                <div className="rectangle rectangle-1MainCustom"></div>
                <div className="rectangle rectangle-2MainCustom"></div>
            </div>
            
            <div className='customParaDivCursed'>
                <p className='customPara'>
                    <h2 className='customTitleCursed'>{titleChanged[index]}</h2>
                    {para[index]}
                </p>
            </div>

            <img src={interior1Icon} onClick={()=>handleIconClick(1)} alt='interior1Icon' id='interior1Icon' className='selectedInteriorIcon'></img>
            <img src={interior2Icon} onClick={()=>handleIconClick(2)} alt='interior2Icon' id='interior2Icon'></img>
            <img src={interior3Icon} onClick={()=>handleIconClick(3)} alt='interior3Icon' id='interior3Icon'></img>
            <img src={interior4Icon} onClick={()=>handleIconClick(4)} alt='interior4Icon' id='interior4Icon'></img>
        </>
    )
};
