import React, { useState } from 'react';
import './Footer.css';
import { useNavigate } from 'react-router-dom';
import cyberLogo from '../assets/footer/cyberLogo.png';
import line1 from '../assets/footer/line1.png';
import footerHeader from '../assets/footer/footerHeader.png';
import NavSplitLine from '../assets/mainInfo/NavSplitLine.png';
import footerArrow from '../assets/footer/footerArrow.png';


import line2 from '../assets/footer/line2.png';
import line3 from '../assets/footer/line3.png';

import ig from '../assets/footer/ig.png';
import tiktok from '../assets/footer/tiktok.png';
import yt from '../assets/footer/yt.png';
import discord from '../assets/footer/discord.png';
import x from '../assets/footer/x.png';

function Footer({ height }) {
    const [showGamesSubMenu, setShowGamesSubMenu] = useState(false);
    const [showMoreSubMenu, setShowMoreSubMenu] = useState(false);
    const navigate = useNavigate();

    const toggleGamesSubMenu = () => {
        setShowGamesSubMenu(!showGamesSubMenu);
    };
    const toggleMoreSubMenu = () => {
        setShowMoreSubMenu(!showMoreSubMenu);
    };
    const handleRouteClick = (targetRoute) => {
        navigate(`/${targetRoute}`);
    };
    const goTo = (weblink) =>{
        window.open(weblink, "_blank");
    }

    return (
        <footer className="footer" style={{ top: height }}>
            <img src={cyberLogo} alt="cyberLogoFooter" className="cyberLogoFooter" />
            {/* <img src={line1} alt="line1Footer" className="line1Footer" /> */}
            <img src={footerHeader} alt="footerHeader" className="footerHeader" />

            <h6 className="GamesFooter footerMenuItem" onClick={toggleGamesSubMenu}>
                PROJECTS &nbsp;
                <i className={'arrowF down'}></i>
            </h6>

            {showGamesSubMenu && (
                <div className="gamesSubMenu">
                    <h6 className="CursedMansionFooter footerMenuExtra underlineFooter" onClick={()=>{handleRouteClick('cursed-mansion')}}>Cursed Mansion</h6>
                    <h6 className="CyberSkiesFooter footerMenuExtra underlineFooter" onClick={()=>{handleRouteClick('cyber-skies')}}>Cyber Skies</h6>
                    <h6 className="QuantumBreachFooter footerMenuExtra underlineFooter" onClick={()=>{handleRouteClick('quantum-breach')}}>Quantum Breach</h6>
                    <h6 className="CosmicLabsFooter footerMenuExtra underlineFooter" onClick={()=>{handleRouteClick('cosmic-labs')}}>Cosmic Labs</h6> 
                    <h6 className="ChimbaRacingFooter footerMenuExtra">Chimba Racing</h6>
                </div>
            )}

            <h6 className="PassFooter footerMenuItem" onClick={()=>{handleRouteClick('pass')}}>PASS</h6>
            <h6 className="AcademyFooter footerMenuItem" onClick={()=>{handleRouteClick('academy')}}>ACADEMY</h6>
            <h6 className="TokenFooter footerMenuItem" onClick={()=>{handleRouteClick('token')}}>TOKEN</h6>


            <h6 className="MoreFooter footerMenuItem" onClick={toggleMoreSubMenu}>MORE &nbsp;<i className="arrowF down"></i></h6>

            {showMoreSubMenu && (
                <div className="moreSubMenu">
                    <h6 className="AboutFooter footerMenuExtraMore underlineFooter" onClick={()=>{handleRouteClick('about')}}>About</h6>
                    <h6 className="FoundationFooter footerMenuExtraMore underlineFooter" onClick={()=>{handleRouteClick('foundation')}}>Foundation</h6>
                    <h6 className="LabsFooter footerMenuExtraMore" onClick={()=>{handleRouteClick('labs')}}>Labs</h6>
                </div>
            )}

            <img className="footerLine1" src={NavSplitLine} alt="NavSplitLine" />
            <img className="footerLine2" src={NavSplitLine} alt="NavSplitLine" />
            <img className="footerLine3" src={NavSplitLine} alt="NavSplitLine" />
            <img className="footerLine4" src={NavSplitLine} alt="NavSplitLine" />
            <img className="footerLine5" src={NavSplitLine} alt="NavSplitLine" />

            <img className="footerArrow" src={footerArrow} alt="footerArrow" />
            <a className='contactFooter' href="mailto:contact@chimbastudios.com">CONTACT@CHIMBASTUDIOS.COM</a>


            <img className="line2Footer" src={line2} alt="line2Footer" />
            <img className="line3Footer" src={line3} alt="line3Footer" />

            
            <img className="igFooter footerIcon" onClick={()=>{goTo('www.instagram.com')}} src={ig} alt="igFooter" />
            <img className="tiktokFooter footerIcon" src={tiktok} onClick={()=>{goTo('www.tiktok.com')}} alt="tiktokFooter" />
            <img className="ytFooter footerIcon" src={yt} onClick={()=>{goTo('https://www.youtube.com/@ChimbaStudios')}} alt="ytFooter" />
            <img className="discordFooter footerIcon" src={discord} onClick={()=>{goTo('https://discord.gg/MxAnWAAW')}} alt="discordFooter" />
            <img className="xFooter footerIcon" src={x} onClick={()=>{goTo('https://twitter.com/ChimbaStudio')}} alt="xFooter" />
        </footer>
    );
}

export default Footer;
