import React from 'react';
import './Css/rewards.css'


import bg from '../../../assets/cyberSkies/bg4.webp'
import line9 from '../../../assets/fullScreenLines/lines5.png'


export function Rewards() {
    return (
        <>
            <img src={bg} alt="bg" className="bgRewards" />
            <img src={line9} alt="line9" className="line9Rewards" />
            <h2 className='mainHeaderSKIESRewards'>REWARDS</h2>
            <div className="group-containerSKIESRewards">
                <div className="rectangle rectangle-1SKIESRewards"></div>
                <div className="rectangle rectangle-2SKIESRewards"></div>
            </div>

            <div className='rewardsParaDivSkies'>
                <p className='rewardsPara'>
                    <span className='rewardsTitleSkies'>Rewards</span><br></br><br></br>
                    Triumph in Cyber Skies reaps rich rewards. <br></br>
                    From unique NFTs to exclusive upgrades, <br></br>
                    your victories earn more than just glory—they empower your gaming legacy.
                </p>
            </div>

            <video autoPlay muted loop playsInline className='rewardVideo'>
                <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/rewardChest.webm" type="video/webm" />
            </video>
        </>
    )
};
