import React, { useEffect, useRef } from 'react';
import { NavBar } from '../../NavBar';

import './Css/cursedMansion.css'

import line1 from '../../../assets/fullScreenLines/lines1.png'
import cursedSeparator from '../../../assets/cursedMansion/separator.png'
import { History } from './History';
import { Characters } from './Characters';
import { NFT } from './NFT';
import { Customization } from './Customization';
import Footer from '../../Footer';
import playButton from '../../../assets/waifus/playButton2.png'

export function CursedMansion() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        window.scrollTo(0, 0);
        // Start playing the video muted
        video.muted = true;
        video.play().catch(error => {
            // Autoplay was prevented
            console.log('Autoplay was prevented:', error);
        });
    }, []);

    const openVideo = () =>{
        window.open("https://youtu.be/9Ah5cWU9EHE", "_blank");
    } 


    return (
        <>
            <NavBar route="cursed-mansion"/>

            <video ref={videoRef} className='cursedBackground' loop muted autoPlay >
                <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/cursedMansion.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <img src={line1} alt="line1" className="line1Cursed" />
            <h2 className='mainHeaderCM'>CURSED MANSION</h2>
            <div className="group-containerCMMain">
                <div className="rectangle rectangle-1Main"></div>
                <div className="rectangle rectangle-2Main"></div>
            </div>

            <img src={cursedSeparator} alt='cursedSeparator' className='cursedSeparator'></img>
            <img src={playButton} alt='playButton' className='playButtonQuantum' onClick={openVideo}></img>

            <History/>
            <Characters/>
            <NFT/>
            <Customization/>
            <Footer height={"294vw"}/>
        </>
    )
};
