import React, { useState } from 'react';
import './Css/character.css'

import background from '../../../assets/cursedMansion/bg3.webp'
import characterBorder from '../../../assets/cursedMansion/characterBorder.webp'
import line5 from '../../../assets/fullScreenLines/lines3.png'
import leftArrow from '../../../assets/cursedMansion/leftArrow.png'
import rightArrow from '../../../assets/cursedMansion/rightArrow.png'

import detective from '../../../assets/cursedMansion/detective.png'
import demon from '../../../assets/cursedMansion/demon.png'
import corp from '../../../assets/cursedMansion/corp.png'
import political from '../../../assets/cursedMansion/political.png'
import witch from '../../../assets/cursedMansion/witch.png'


import seperator3 from '../../../assets/cursedMansion/separator.png'

export function Characters() {
    const [currentCharacterIndex, setCurrentCharacterIndex] = useState(0);
    const characterImages = [detective, political, corp, witch, demon];
    const characterTitle = ["PRIVATE INVESTIGATOR", "THE ARISTOCRAT", "THE COLONEL", "THE WITCH", "THE DEMON"];
    const characterName =["Lucius Blackwood", "Baron Cruelheart", "Brutus Bloodworth", "Morgause", "Asmodeus"]
    const characterDesc = [
        "Lucius Blackwood, the detective and mercenary, is driven by an insatiable thirst for wealth, willing to do whatever it takes for a handful of coins.", 
        "Baron Cruelheart, the aristocrat of the 17th century, finds pleasure in life's luxuries at the expense of the toil and even lives of his subordinates.",
        "Brutus Bloodworth, the military colonel, harbors an insatiable love for power and will stop at nothing to achieve his desires, even if it means implicating the innocent or betraying his own.",
        "Morgause, the 17th-century witch, stands as a sinister shadow within the pages of history. Her piercing gaze and malevolent laughter betray the darkness she carries.",
        "Asmodeus, the mocking demon, revels in sowing pure terror among his victims, feeding off the fear like a morbid and insatiable connoisseur."
    ]

    const handleLeftArrowClick = () => {
        setCurrentCharacterIndex(prevIndex =>
            (prevIndex === 0 ? characterImages.length - 1 : prevIndex - 1)
        );
    };

    const handleRightArrowClick = () => {
        setCurrentCharacterIndex(prevIndex =>
            (prevIndex === characterImages.length - 1 ? 0 : prevIndex + 1)
        );
    };

    return (
        <>
            <img src={background} alt='background' className='background3Cursed' />
            <img src={line5} alt='line5' className='line5Cursed' />
            <h2 className='charHeaderCM'>CHARACTERS</h2>
            <div className="group-containerCharMain">
                <div className="rectangle rectangle-1MainChar"></div>
                <div className="rectangle rectangle-2MainChar"></div>
            </div>

            <img src={characterBorder} alt='characterBorder' className='characterBorderCursed' />
            <img src={leftArrow} alt='leftArrow' className='leftArrowCursed' onClick={handleLeftArrowClick} />
            <img src={rightArrow} alt='rightArrow' className='rightArrowCursed' onClick={handleRightArrowClick} />
            <h1 className='detectiveTitle'>{characterTitle[currentCharacterIndex]}</h1>
            <img src={characterImages[currentCharacterIndex]} alt='character' className='detectiveCursed' />

            <div className='characterParaDivCursed'>
                <p className='historyPara'>
                    <span className='historyTitleCursed'>Selection</span><br />
                    Choose your fate with a cast of diverse characters,<br />
                    each with unique abilities and a mysterious past. <br />
                    From the stoic Priest to the cunning Politician, <br />
                    define your strategy in a world of deception.<br />
                </p>
            </div>

            <div className='loreDivCursed'>
                <p className='loreParaCursed'>
                    <span className='loreTitleCursed'>{characterName[currentCharacterIndex]}</span><br /><br />
                    {characterDesc[currentCharacterIndex]}
                </p>
            </div>

            <img src={seperator3} alt='seperator3' className='seperator3' />
        </>
    );
};
