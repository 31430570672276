import React, { useState }  from 'react';
import './Css/token2.css'

import background from '../../assets/token/background2.png'
import line4 from '../../assets/token/line4.png'
import line5 from '../../assets/token/line5.png'
import line6 from '../../assets/token/line6.png'
import playButton from '../../assets/waifus/playButton2.png'

import token1 from '../../assets/token/token1.png'
import token2 from '../../assets/token/token2.png'
import token3 from '../../assets/token/token3.png'
import token4 from '../../assets/token/token4.png'
import token1Logo from '../../assets/token/token1Logo.png';
import token2Logo from '../../assets/token/token2Logo.png';
import token3Logo from '../../assets/token/token3Logo.png';
import token4Logo from '../../assets/token/token4Logo.png';

export function Token2() {
    const bottomText = [
        "Explore a world where Chimba Tokens open the door to exclusive digital experiences and premium access across our ecosystem.",
        "Follow our hero's journey through the Chimba universe, using tokens to navigate challenges and unlock unique capabilities.",
        "Even in a sea of volatile crypto, watch Pepe rejoice as the Chimba Tokens begin to rain down.",
        "Discover the expansive range of Chimba IPs, from adventurous tales to immersive experiences, all powered by Chimba Tokens."
    ]

    const [selectedToken, setSelectedToken] = useState(token1Logo);
    const [para, setPara] = useState(bottomText[0])
    
    const handleTokenClick = (tokenLogoSrc) => {
        setSelectedToken(tokenLogoSrc);

        if(tokenLogoSrc === token1Logo) setPara(bottomText[0])
        else if(tokenLogoSrc === token2Logo) setPara(bottomText[1])
        else if(tokenLogoSrc === token3Logo) setPara(bottomText[2])
        else if(tokenLogoSrc === token4Logo) setPara(bottomText[3])
    };

    const openVideo = () => {
        if(selectedToken === token2Logo) window.open("https://www.youtube.com/watch?v=cRcfPG9LDvw", "_blank")
        else if(selectedToken === token3Logo) window.open("https://youtu.be/vkYUS2F6iZ4", "_blank")
    }

    // Helper function to determine if the current token has a video
    const hasVideo = () => {
        return selectedToken === token2Logo || selectedToken === token3Logo;
    }

    return(
        <>
            <img src={background} alt='background' className='tokenBackground2'></img>
            <img src={line4} alt='line4' className='line4Token'></img>
            <img src={line5} alt='line5' className='line5Token'></img>
            <img src={line6} alt='line6' className='line6Token'></img>

            <h2 className='tokenHeader2'>TOKEN</h2>
            <div className="group-container2">
                <div className="rectangle rectangle-1"></div>
                <div className="rectangle rectangle-2"></div>
            </div>

            {hasVideo() && (
                <img src={playButton} alt='playButtonToken' className='playButtonToken' onClick={openVideo}></img>
            )}

            <div className='tokenParaDiv2'>
                <p className='tokenPara'>
                    <span className='tokenParaHeader'>VERSATILITY</span><br></br>
                    The Chimba Token is a versatile gaming coin, seamlessly integrating<br></br> 
                    across our games, NFTs, and subscriptions, fueling a cohesive ecosystem. 
                </p>
            </div>

            <img 
                src={selectedToken} 
                alt='chimbaToken' 
                className={`chimbaToken2 ${hasVideo() ? 'clickable' : ''}`}
                onClick={() => hasVideo() && openVideo()}
                style={{ cursor: hasVideo() ? 'pointer' : 'default' }}
            ></img>

            <img src={token1} onClick={() => handleTokenClick(token1Logo)} alt='token1' className={`token1 ${selectedToken === token1Logo ? 'selectedToken' : ''}`}></img>
            <img src={token2} onClick={() => handleTokenClick(token2Logo)} alt='token2' className={`token2 ${selectedToken === token2Logo ? 'selectedToken' : ''}`}></img>
            <img src={token3} onClick={() => handleTokenClick(token3Logo)} alt='token3' className={`token3 ${selectedToken === token3Logo ? 'selectedToken' : ''}`}></img>
            <img src={token4} onClick={() => handleTokenClick(token4Logo)} alt='token4' className={`token4 ${selectedToken === token4Logo ? 'selectedToken' : ''}`}></img>

            <p className='bottomTokenText'>
                {para}
            </p>
        </>
    );
};