import React from 'react'
import { useGLTF } from '@react-three/drei'
import controlModel from '../../assets/3dModels/Control.glb' 

export function Control(props) {
  const { nodes, materials } = useGLTF(controlModel)
  return (
    <group {...props} dispose={null}>
      <group position={[0.404, -0.404, 3.432]} rotation={[Math.PI / 2, 0, 0]} scale={0.009}>
        <mesh geometry={nodes.Diorama_maqueta_1.geometry} material={materials.Cofre} />
        <mesh geometry={nodes.Diorama_maqueta_2.geometry} material={materials['Material.002']} />
        <mesh geometry={nodes.Diorama_maqueta_3.geometry} material={materials.Control} />
        <mesh geometry={nodes.Diorama_maqueta_4.geometry} material={materials['control mango']} />
      </group>
      <mesh geometry={nodes.Cylinder002.geometry} material={materials['Material.001']} position={[2.716, -0.383, 0.572]} rotation={[0.13, 0.202, 0.758]} scale={[0.694, 0.03, 0.694]} />
      <mesh geometry={nodes.Cylinder011.geometry} material={materials['Material.004']} position={[-0.372, -1.841, 2.595]} rotation={[-2.981, -1.253, -2.966]} scale={[0.331, 0.014, 0.331]} />
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.003']} position={[0.404, 10.24, 3.432]} rotation={[-Math.PI, 0.809, -Math.PI]} scale={[74.305, 13.739, 72.537]} />
    </group>
  )
}

useGLTF.preload('../../assets/3dModels/Control.glb')