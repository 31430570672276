import React, { useState } from 'react';
import './Css/characters.css'

import blackBG from '../../../assets/waifus/blackBg.png'
import quantumLogo from '../../../assets/waifus/quantumLogo.png'
import characterLine1 from '../../../assets/fullScreenLines/QB/lines3.png'

import textArrow from '../../../assets/waifus/textArrow.png'
import iconLine from '../../../assets/waifus/iconLine.png'

import icon1 from '../../../assets/waifus/icon1.png'
import icon2 from '../../../assets/waifus/icon2.png'
import icon3 from '../../../assets/waifus/icon3.png'
import icon4 from '../../../assets/waifus/icon4.png'
import icon5 from '../../../assets/waifus/icon5.png'


import char1 from '../../../assets/waifus/char1.png'
import char2 from '../../../assets/waifus/char2.png'
import char3 from '../../../assets/waifus/char3.png'
import char4 from '../../../assets/waifus/char4.png'
import char5 from '../../../assets/waifus/char5.png'


export function Characters() {

    const titles = ["Ellis Adams", "Anna Thompson", "Zoe Sinclair", "Diana Black", "Harmony Briggs"]
    const descriptions =[
        "Ellis Adams, a 22-year-old game developer at Chimba Games, infuses her projects with the hope of positively impacting players. Despite her indecisiveness, Ellis's vibrant personality and creative spirit make her a pivotal team player, ready to tackle any obstacle.",
        "At 17, Anna Thompson is a Chimba Academy student caught between parental pressures and her quest for personal triumph. Initially risk-averse and fearful of failure, Anna's journey reveals her innate problem-solving courage, inspiring her to rise above her challenges.",
        "Zoe Sinclair, a 21-year-old savvy investor with Chimba Token, uses her financial acumen to support meaningful causes subtly. Behind her sophisticated exterior lies a sharp, analytical mind skilled in navigating the complex currents of the economic world.",
        "Diana Black, 32, is a lead scientist at Chimba Labs, driven by a passion for immersive experiences and a heavy conscience over past disasters. Her work is her redemption, as she delves into the mysteries that jeopardize Chimba, striving to restore balance.",
        "At 26, Harmony Briggs works at the Chimba Foundation, dedicating her life to addressing educational and social inequalities. Her altruistic nature often leads her to self-sacrifice, protecting those she sees as vulnerable while pushing her limits.",
    ]
    const [characterSelected, setCharacterSelected] = useState(char4)
    const [titleSelected, setTitleSelected] = useState(titles[3])
    const [descriptionSelected, setDescSelected] = useState(descriptions[3])

    const handleIconClick = (index) => {
        for(var i=1;i<=5; i++){
            document.getElementById(`icon${i}`).classList.remove("iconQuantumSelected")
        }
        if(index === 1){
            setCharacterSelected(char1)
            setTitleSelected(titles[0])
            setDescSelected(descriptions[0])
            document.getElementById("icon1").classList.add("iconQuantumSelected")
        }
        else if(index === 2){
            setCharacterSelected(char2)
            setTitleSelected(titles[1])
            setDescSelected(descriptions[1])
            document.getElementById("icon2").classList.add("iconQuantumSelected")
        }
        else if(index === 3){
            setCharacterSelected(char3)
            setTitleSelected(titles[2])
            setDescSelected(descriptions[2])
            document.getElementById("icon3").classList.add("iconQuantumSelected")
        }
        else if(index === 4){
            setCharacterSelected(char4)
            setTitleSelected(titles[3])
            setDescSelected(descriptions[3])
            document.getElementById("icon4").classList.add("iconQuantumSelected")
        }
        else if(index === 5){
            setCharacterSelected(char5)
            setTitleSelected(titles[4])
            setDescSelected(descriptions[4])
            document.getElementById("icon5").classList.add("iconQuantumSelected")
        }
    }
    return(
        <>
            <img src={blackBG} alt='blackBG' className='blackBG2'></img>
            <img src={quantumLogo} alt='quantumLogo' className='quantumLogo3'></img>
            <img src={characterLine1} alt='characterLine1' className='characterLine1'></img>

            <h2 className='mainHeaderQBChar'>CHARACTERS</h2>
            <div className="group-containerQBChar">
                <div className="rectangle rectangle-1QBChar"></div>
                <div className="rectangle rectangle-2QBChar"></div>
            </div>


            <div className='characterParaDivQuantum'>
                <p className='historyPara'>
                    <span className='characterTitleQuantum'>{titleSelected}</span><br></br>
                    {descriptionSelected}
                </p>
            </div>


            <img src={icon1} alt='icon1' onClick={()=>handleIconClick(1)} className='iconQuantum' id='icon1'></img>
            <img src={icon2} alt='icon2' onClick={()=>handleIconClick(2)} className='iconQuantum' id='icon2'></img>
            <img src={icon3} alt='icon3' onClick={()=>handleIconClick(3)} className='iconQuantum' id='icon3'></img>
            <img src={icon4} alt='icon4' onClick={()=>handleIconClick(4)} className='iconQuantum iconQuantumSelected'id='icon4'></img>
            <img src={icon5} alt='icon5' onClick={()=>handleIconClick(5)} className='iconQuantum' id='icon5'></img>

            <img src={characterSelected} alt='quantamCharacterSelected' className='quantamCharacterSelected'></img>
        </>
    )
};