import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import racingModel from '../../assets/3dModels/Racing.glb' 

export function Racing(props) {
  const { nodes, materials } = useGLTF(racingModel)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.carrito_remaster005.geometry} material={materials.llantas} />
      <mesh geometry={nodes.carrito_remaster006.geometry} material={materials.Carro} />
      <mesh geometry={nodes.Circle.geometry} material={materials['Material.008']} />
      <mesh geometry={nodes.Circle004.geometry} material={materials['Material.010']} />
      <mesh geometry={nodes.Cube001.geometry} material={materials.publicitaria} />
      <mesh geometry={nodes.Plane001.geometry} material={materials.Calle} />
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.003']} />
      <mesh geometry={nodes.Plane008.geometry} material={materials['Material.005']} position={[1.131, 0.074, -0.622]} rotation={[-Math.PI, 0.617, -Math.PI]} scale={[1.368, 1.403, 2.826]} />
      <mesh geometry={nodes.Su54_OBJ014.geometry} material={materials['Material.011']} position={[0.642, 0.037, -4.088]} rotation={[-Math.PI, -1.362, -Math.PI / 2]} scale={[0.003, 0.003, 0.011]} />
      <mesh geometry={nodes.Plane003.geometry} material={materials['Material.002']} />
      <mesh geometry={nodes.Plane003_1.geometry} material={materials['Material.004']} />
      <mesh geometry={nodes.terrenaitor006_1.geometry} material={materials.Carrito_futurista} />
      <mesh geometry={nodes.terrenaitor006_2.geometry} material={materials.Llantas_futuristas} />
    </group>
  )
}

useGLTF.preload('../../assets/3dModels/Racing.glb')
