import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import zoeModel from '../../assets/3dModels/Zoe.glb' 

export function Zoe(props) {
  const { nodes, materials } = useGLTF(zoeModel)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Cube001.geometry} material={materials.M_Diorama} />
      <mesh geometry={nodes.Cube005.geometry} material={materials['Material.018']} />
      <mesh geometry={nodes.Cube007.geometry} material={materials['Material.020']} />
      <mesh geometry={nodes.Sphere003.geometry} material={materials['Material.021']} scale={[1, 1.179, 1]} />
      <mesh geometry={nodes.Body.geometry} material={materials.M_Zoe_Body} />
      <mesh geometry={nodes.Face.geometry} material={materials.M_Zoe_Face} />
      <mesh geometry={nodes.Cube003.geometry} material={materials['Material.014']} />
      <mesh geometry={nodes.Cube003_1.geometry} material={materials['Material.015']} />
    </group>
  )
}

useGLTF.preload('../../assets/3dModels/Zoe.glb')
