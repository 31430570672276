import React from 'react';

import './Css/history.css'

import welcomeImage from '../../../assets/waifus/welcomeImage.png'
import quantumLogo from '../../../assets/waifus/quantumLogo.png'
import blackBG from '../../../assets/waifus/blackBg.png'

import historyLine1 from '../../../assets/fullScreenLines/QB/lines2.png'


import historyChimbaLogo from '../../../assets/waifus/historyChimbaLogo.png'
export function History() {

    return(
        <>
            <img src={blackBG} alt='blackBG' className='blackBG'></img>
            <img src={welcomeImage} alt='welcomeImage' className='welcomeImage'></img>
            <img src={quantumLogo} alt='quantumLogo' className='quantumLogo2'></img>

            <img src={historyLine1} alt='historyLine1' className='historyLine1'></img>

            <h2 className='storyHeaderQB'>STORY</h2>
            <div className="group-containerQB">
                <div className="rectangle rectangle-1"></div>
                <div className="rectangle rectangle-2"></div>
            </div>

            <div className='historyParaDiv'>
                <p className='historyPara'>
                    <span className='historyTitle'>A Virtual Saga</span><br></br>
                    Dive into Quantum Breach, an anime saga set in a world where virtual   
                    reality and danger intersect. Anna, a gaming aficionado from Chimba Academy,
                    faces a sinister virus wreaking havoc both online and offline. Teaming up with 
                    a band of skilled allies, they tackle a deadly digital menace that blurs the lines 
                    between virtual and reality. As casualties rise, the race to thwart a master 
                    hacker turns personal, leading to a high-stakes showdown where the virtual 
                    world's fate hangs in the balance. This gripping series explores the courage 
                    found in the face of digital chaos, questioning reality itself. 

                </p>
            </div>

            <img src={historyChimbaLogo} alt='historyChimbaLogo' className='historyChimbaLogo'></img>
        </>
    )
};