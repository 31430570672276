export const updateStyles = (leftValue, zIndexValue) => {
    ['backgroundPass', 'mousePass', 'mouseLinePass', 'scrollToNavPass'].forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (element) {
        if (elementId === 'backgroundPass') {
          element.style.left = leftValue;
        } else {
          element.style.zIndex = zIndexValue;
        }
      }
    });
  };
  
  export const boxTriggered = (trigger, divName, attenName) => {
    const accessAcademyDiv = document.getElementById(divName);
    const attention = document.getElementById(attenName);

    accessAcademyDiv.style.display = trigger ? 'block' : 'none';
    attention.style.display = trigger ? 'none' : 'block';
  };
  