import React from 'react';
import './Css/history.css'


import bg from '../../../assets/cyberSkies/bg.png'
import line4 from '../../../assets/fullScreenLines/lines2.png'


export function History() {
    return (
        <>
            <img src={bg} alt="bg" className="bgSkies" />
            <img src={line4} alt="line4" className="line4Skies" />

            <h2 className='storyHeaderCS'>STORY</h2>
            <div className="group-containerCS">
                <div className="rectangle rectangle-1"></div>
                <div className="rectangle rectangle-2"></div>
            </div>

            <div className='historyParaDivSkies'>
                <p className='historyPara'>
                    <span className='historyTitleSkies'>LORE</span><br></br>
                    Enter the high-stakes world of Cyber Skies, <br></br>
                    where pilots clash against a looming AI threat<br></br>
                    in a cybernetic showdown.<br></br><br></br>
                    Here, strategy and skill soar to new heights.
                </p>
            </div>

        </>
    )
};
