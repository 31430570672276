import React, { useEffect, useRef } from "react";
import { NavBar } from "../../NavBar";

import "./Css/chimbaracing.css";

import line1 from "../../../assets/fullScreenLines/lines1.png";

import Footer from "../../Footer";

import scrollToNav from "../../../assets/waifus/scrollToNav.png";
import mouse from "../../../assets/waifus/mouse.png";
import comingSoon from "../../../assets/chimbaracing/coming_soon.png";

export function ChimbaRacing() {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;
    window.scrollTo(0, 0);
    // Start playing the video muted
    video.muted = true;
    video.play().catch((error) => {
      // Autoplay was prevented
      console.log("Autoplay was prevented:", error);
    });
    if (window.innerWidth > 1000) {
      window.scrollBy(0, window.innerWidth * 0.05);
    }
  }, []);

  const openVideo = () => {
    window.open("https://www.youtube.com/watch?v=aJ0-_YaF_OQ", "_blank");
  };

  return (
    <>
      <NavBar route="chimba-racing" />

      <video
        ref={videoRef}
        className="racingBackground"
        loop
        muted
        autoPlay
      >
        <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/chimbaracing.mp4" />
        Your browser does not support the video tag.
      </video>
      <img src={comingSoon} className="comingSoonRacing"></img>

      {/* <img src={line3} alt="line3" className="line3Racing" /> */}
      <img src={scrollToNav} alt="scrollToNav" className="scrollToNavRacing" />
      <img src={mouse} alt="mouse" className="mouseRacing" />

      <img src={line1} alt="line1" className="line1Skies" />
      {/* <img src={line2} alt="line2" className="line2Skies" /> */}
      <h2 className="mainHeaderRacing">CHIMBA RACING</h2>
      <div className="group-containerRacing">
        <div className="rectangle rectangle-1Racing"></div>
        <div className="rectangle rectangle-2Racing"></div>
      </div>
      {/* <img src={playButton} alt='playButton' className='playButtonQuantum' onClick={openVideo}></img> */}

      <Footer height={"55.2vw"} />
    </>
  );
}
