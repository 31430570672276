import Lottie from 'react-lottie';
import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';

import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';

import chimbaTokenTitle from '../../assets/pass/chimbaTokenTitle.png';
import chimbaToken from '../../assets/pass/chimbaToken.png'
import chimbaTokenLine from '../../assets/pass/chimbaTokenLine.png';
import tokensImage from '../../assets/pass/clickAndHold/Tokens.png';

export function ChimbaTokens({isClickAndHold ,setIsClickAndHold}) {
  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attentionChest");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionChest");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionChest");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const chestBoxElement = document.getElementById('clickHoldChest');
    chestBoxElement.style.filter = `brightness(${brightness})`;

    return () => {
      chestBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='chestVisibility'>
        <div 
          id='chestBox'
          onMouseDown={()=>handleMouseDown("chimbaTokenDiv")}
          onMouseUp={()=>handleMouseUp("chimbaTokenDiv")}
          onMouseLeave={() => handleMouseLeave("chimbaTokenDiv")}
        ></div>
        <img id='clickHoldChest' alt='clickHoldChest' src={clickAndHold}></img>
      </div>
      <div id='attentionChest'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='chimbaTokenDiv'>
        <img src={chimbaTokenTitle} alt='accessAcademyTitle' className='accessAcademyTitle'></img>
        <img src={chimbaTokenLine} alt='chimbaTokenLine' className='chimbaTokenLine'></img>
        <p className='accessAcademyPara'>
        Chimba Pass holders are eligible<br></br>
        for exclusive Chimba Token airdrops,<br></br>
        enhancing their digital experience with <br></br>
        added in-platform value.<br></br>
        </p>
        <img src={chimbaToken} alt="chimbaToken" className="chimbaToken"></img>
        <img src={tokensImage} alt='accessAcademyPH' className='accessAcademyPH'></img>
      </div>
    </>
  );
}