import React from 'react';
import './Css/history.css'

import background from '../../../assets/cursedMansion/bg2.png'
import line3 from '../../../assets/fullScreenLines/lines2.png'


import separator2 from '../../../assets/cursedMansion/separator2.png'

export function History() {


    return (
        <>
            <img src={background} alt='background' className='background2Cursed'></img>

            <img src={line3} alt='line2' className='line2Cursed'></img>

            <h2 className='storyHeaderCM'>STORY</h2>
            <div className="group-containerCM">
                <div className="rectangle rectangle-1"></div>
                <div className="rectangle rectangle-2"></div>
            </div>

            <div className='historyParaDivCursed'>
                <p className='historyPara'>
                    <span className='historyTitleCursed'>LORE</span><br></br>
                    Unravel the dark secrets of Cursed Mansion, <br></br>
                    a gothic haven turned sinister. Navigate the <br></br>
                    treacherous halls where aristocracy meets <br></br>
                    anarchy in a bid for survival.
                </p>
            </div>
            
            <img src={separator2} alt='separator2' className='separator2'></img>
        </>
    )
};
