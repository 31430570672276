import React, { useState } from 'react';

import './Css/world.css'

import quantumLogo from '../../../assets/waifus/quantumLogo.png'
import worldBG from '../../../assets/waifus/worldBG.png'
import world1BG from '../../../assets/waifus/world1BG.webp'
import world2BG from '../../../assets/waifus/world2BG.webp'
import world3BG from '../../../assets/waifus/world3BG.webp'



import line10 from '../../../assets/fullScreenLines/QB/lines5.png'


import iconWorld from '../../../assets/waifus/iconWorld.webp'
import icon1World from '../../../assets/waifus/icon1World.webp'
import icon2World from '../../../assets/waifus/icon2World.webp'
import icon3World from '../../../assets/waifus/icon3World.webp'

import textArrow from '../../../assets/waifus/textArrow.png'
export function World() {

    const titles = ["World", "Chimba Academy", "Cursed Mansion", "Infection"]
    const descriptions = [
        "Venture into the expansive open world of Quantum Breach, where virtual landscapes are vast and filled with dynamic interactions, serving as the battleground for digital dominance.",
        "At the heart of the series, Chimba Academy is not just a school but the epicenter of youthful innovation, where future heroes harness their skills amidst emerging digital threats.",
        "Delve into the Cursed Mansion, a recurring and pivotal game setting that mirrors the darkness of the virtual threat, challenging characters with its shifting, labyrinthine halls.",
        "The city in Quantum Breach transforms into a haunting reflection of the virus's power, a once vibrant urban expanse now a treacherous maze filled with digital phantoms and real dangers."
    ]

    const [worldSelected, setWorldSelected] = useState(worldBG)
    const [titleSelected, setTitleSelected] = useState(titles[0])
    const [descriptionSelected, setDescSelected] = useState(descriptions[0])

    const handleIconClick = (index) => {
        for(var i=1;i<=4; i++){
            document.getElementById(`icon${i}World`).classList.remove("iconWorldSelected")
        }
        if(index === 1){
            setWorldSelected(worldBG)
            setTitleSelected(titles[0])
            setDescSelected(descriptions[0])
            document.getElementById("icon1World").classList.add("iconWorldSelected")
        }
        else if(index === 2){
            setWorldSelected(world1BG)
            setTitleSelected(titles[1])
            setDescSelected(descriptions[1])
            document.getElementById("icon2World").classList.add("iconWorldSelected")
        }
        else if(index === 3){
            setWorldSelected(world2BG)
            setTitleSelected(titles[2])
            setDescSelected(descriptions[2])
            document.getElementById("icon3World").classList.add("iconWorldSelected")
        }
        else if(index === 4){
            setWorldSelected(world3BG)
            setTitleSelected(titles[3])
            setDescSelected(descriptions[3])
            document.getElementById("icon4World").classList.add("iconWorldSelected")
        }
    }
    return(
        <>
            <img src={worldSelected} alt='worldBG' className='worldBG'></img>
            <img src={quantumLogo} alt='quantumLogo' className='quantumLogo5'></img>

            <img src={line10} alt='line10' className='line10Quantum'></img>
            <h2 className='mainHeaderQBWorld'>WORLD</h2>
            <div className="group-containerQBWorld">
                <div className="rectangle rectangle-1QBWorld"></div>
                <div className="rectangle rectangle-2QBWorld"></div>
            </div>



            <img src={iconWorld}  onClick={()=>handleIconClick(1)} alt='iconWorld'  id="icon1World" className='iconWorldSelected'></img>
            <img src={icon1World} onClick={()=>handleIconClick(2)} alt='icon1World' id="icon2World" ></img>
            <img src={icon2World} onClick={()=>handleIconClick(3)} alt='icon2World' id="icon3World" ></img>
            <img src={icon3World} onClick={()=>handleIconClick(4)} alt='icon3World' id="icon4World" ></img>

            <div className='worldParaDivQuantum'>
                <p className='historyPara'>
                    <span className='worldTitleQuantum'>{titleSelected}</span><br></br>
                    {descriptionSelected}
                </p>
            </div>
        </>
    )
};