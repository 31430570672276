import React from 'react';
import './Css/ships.css'


import bg from '../../../assets/cyberSkies/bg4.webp'
import line7 from '../../../assets/fullScreenLines/lines4.png'

import diagram from '../../../assets/cyberSkies/diagram.webp'


export function Ships() {
    return (
        <>
            <img src={bg} alt="bg" className="bgShips" />
            <img src={line7} alt="line7" className="line7Ships" />
            <h2 className='mainHeaderSKIESShips'>SHIPS</h2>
            <div className="group-containerSKIESShips">
                <div className="rectangle rectangle-1SKIESShips"></div>
                <div className="rectangle rectangle-2SKIESShips"></div>
            </div>

            <img src={diagram} alt="diagram" className="diagramShips" />

            <video autoPlay muted loop playsInline className='shipVideo'>
                <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/shipVideo.webm" type="video/webm" />
            </video>

            <div className='shipsParaDivSkies'>
                <p className='shipsPara'>
                    <span className='shipsTitleSkies'>Talent Trees</span><br></br>
                    Maneuver through the skies with customizable ships, each boasting unique talent <br></br>
                    trees and abilities. <br></br><br></br>
                    Master your role, from Healer, Tank, Cannon, and Melee to dominate the aerial battlefield.
                </p>
            </div>
        </>
    )
};
