import React, { useEffect, useState, useRef } from 'react';
import { NavBar } from '../NavBar';
import { PassInfo } from './PassInfo';

import './Css/pass.css'

import line1Pass from '../../assets/pass/line1.png'
import chimbaLogoPass from '../../assets/pass/chimbaLogo.png'

import playButton from '../../assets/pass/playButton.png'
import controller from '../../assets/pass/controller.png'

import mouse from '../../assets/pass/mouse.png'
import mouseLine from '../../assets/pass/mouseLine.png'
import scrollToNav from '../../assets/pass/scrollToNav.png'

import videoPlayButton from '../../assets/pass/videoPlayButton.png'
import smallPlayButton from '../../assets/pass/smallPlayButton.png'
import smallPauseButton from '../../assets/pass/smallPauseButton.png'
import fullScreenVideo from '../../assets/pass/fullScreenVideo.png'
import closeVideoButton from '../../assets/pass/closeVideoButton.png'



export function Pass() {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const videoRef = useRef(null);

    useEffect(() => {
        // Set body overflow to hidden when Pass component mounts
        document.body.style.overflowY = 'hidden';
        // Cleanup effect when the component unmounts
        return () => {
          document.body.style.overflowY = 'auto';
        };
    }, []);

    useEffect(() => {
        const video = videoRef.current;

        const updateProgress = () => {
            setCurrentTime(video.currentTime);
            setDuration(video.duration);
        };

        const handleEnded = () => {
            setIsVideoPlaying(false);
        };

        if (video) {
            video.addEventListener('timeupdate', updateProgress);
            video.addEventListener('ended', handleEnded);

            return () => {
                video.removeEventListener('timeupdate', updateProgress);
                video.removeEventListener('ended', handleEnded);
            };
        }
    }, []);

    const handleProgressBarClick = (e) => {
        const video = videoRef.current;
        const progressBar = document.querySelector('.progress-bar-container');

        const clickX = e.clientX - progressBar.getBoundingClientRect().left;
        const progressBarWidth = progressBar.clientWidth;
        const seekTime = (clickX / progressBarWidth) * duration;

        video.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const openVideo = () =>{
        window.open("https://www.youtube.com/watch?v=S1d4Kn0_u2w", "_blank");
    }   
    const playVideo = () => {
        const video = document.getElementById('passVideo');
        const playButton = document.getElementById('videoPlayButton')
        const smallPlayButton = document.getElementById('smallPlayButton');
        const smallPauseButton = document.getElementById('smallPauseButton');
    
        if (isVideoPlaying) {
            video.pause();
            setIsVideoPlaying(false);
            smallPauseButton.style.display = "none";
            smallPlayButton.style.display = "block";
            playButton.style.display = "block"
        } else {
            video.play();
            setIsVideoPlaying(true);
            smallPlayButton.style.display = "none";
            playButton.style.display = "none"
            smallPauseButton.style.display = "block";
        }
    };
    const fullScreen = () => {
        const video = document.getElementById('passVideo');
        video.requestFullscreen();
    }
    const closeVideo = () => {
        const video = document.getElementById('passVideo');
        const playButton = document.getElementById('videoPlayButton')

        video.pause();
        video.currentTime = 0;
        setIsVideoPlaying(false);
        playButton.style.display = "block"
        document.getElementById('video-container').style.display = "none"
    };

    const progressBarStyles = {
        width: `${(currentTime / duration) * 100}%`,
    };

    return(
        <>
            <NavBar route="pass"/>

            <h2 className='passHeader'>CHIMBA PASS</h2>
            <div className="group-containerPass">
                <div className="rectangle rectangle-1Pass"></div>
                <div className="rectangle rectangle-2Pass"></div>
            </div>
            
            
            <img src={line1Pass} alt='line1Pass' className='line1Pass'></img>
            <img src={chimbaLogoPass} alt='chimbaLogoPass' className='chimbaLogoPass'></img>

            <h2 className='trailer' onClick={openVideo}>TRAILER</h2>
            <img src={playButton} alt='playButton' className='playButton' onClick={openVideo}></img>
            <img src={controller} alt='controller' className='controller' onClick={openVideo}></img>

            <p className='passPara'>
            The Chimba Pass is elite membership <br></br>
            in the Chimba Universe. Enjoy early beta testing,<br></br>
            priority game releases, exclusive event invites, discounted mints, <br></br>
            unique airdrops, privileged Discord roles, and a voice in our future.<br></br>
            <br></br><br></br>
            Learn with Chimba Academy and witness our creative journey, <br></br>
            all while contributing to philanthropy. Your key to the next level in gaming.
            </p>

            <img src={mouse} alt='mouse' id='mousePass'></img>
            <img src={mouseLine} alt='mouseLine' id='mouseLinePass'></img>
            <img src={scrollToNav} alt='scrollToNav' id='scrollToNavPass'></img>


            <div id="video-container">
                <img src={videoPlayButton} alt="videoPlayButton" id="videoPlayButton" onClick={playVideo} />
                <img src={smallPlayButton} alt="smallPlayButton" id="smallPlayButton" onClick={playVideo} />
                <img src={smallPauseButton} alt="smallPauseButton" id="smallPauseButton" onClick={playVideo} />
                <img src={fullScreenVideo} alt="fullScreenVideo" id="fullScreenVideo" onClick={fullScreen} />
                <img src={closeVideoButton} alt="closeVideoButton" id="closeVideoButton" onClick={closeVideo} />
                <video id="passVideo" ref={videoRef}>
                    <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/chimbaPassTrailer.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div className="progress-bar-container" onClick={handleProgressBarClick}>
                    <div className="progress-bar" style={progressBarStyles}></div>
                </div>
            </div>
            <PassInfo/>


        
        </>
    )
};