import React from 'react';

import './Css/virus.css'

import quantumLogo from '../../../assets/waifus/quantumLogo.png'
import virusBG from '../../../assets/waifus/virusBG.png'

import line4 from '../../../assets/fullScreenLines/QB/lines4.png'



export function Virus() {
    return(
        <>
            <img src={virusBG} alt='virusBG' className='virusBG'></img>
            <img src={quantumLogo} alt='quantumLogo' className='quantumLogo4'></img>

            <img src={line4} alt='line4' className='line4Quantum'></img>


            <h2 className='virusHeaderQB'>VIRUS</h2>
            <div className="group-containerVQB">
                <div className="rectangle rectangle-1VQB"></div>
                <div className="rectangle rectangle-2VQB"></div>
            </div>

            <span className='virusTitleQuantum'>The Quantum Virus</span><br></br>
            <p className='virusPara'>   
                A digital scourge that transcends virtual boundaries, wreaking havoc in the virtual world and <br></br>
                threatening real-world consequences.
                Its evolution endangers both the digital and physical realms, <br></br>
                forcing players to confront unforeseen challenges.
            </p>

        </>
    )
};