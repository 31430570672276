import React, { useEffect, useState } from "react";
import './Css/Loader.css'; 

import leftGate from '../../assets/loadingScreen/left.png';
import rightGate from '../../assets/loadingScreen/right.png';
import loadingBar from '../../assets/loadingScreen/loadingBar.png';
import loadingIcon from '../../assets/loadingScreen/loadingSymbol.png'
import leftGateMobile from '../../assets/loadingScreen/leftMobile.png';
import rightGateMobile from '../../assets/loadingScreen/rightMobile.png';
import { Html, useProgress } from "@react-three/drei";

export function Loader() {
  const { progress } = useProgress();
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [slideAway, setSlideAway] = useState(false);
  const isMobile = window.innerWidth < 800;

  const leftGateMobileClass = isMobile ? 'mobileLeft' : '';
  const rightGateMobileClass = isMobile ? 'mobileRight' : '';
  const loadingLabelMobileClass = isMobile ? 'mobileLabel' : '';
  const loadingIconMobileClass = isMobile ? 'mobileIcon' : '';
  const loadingBarBackgroundMobileClass = isMobile ? 'mobileBackgroundBar' : '';
  const loadingBarMobileClass = isMobile ? 'mobileBar' : '';

  useEffect(() => {
    if (progress === 100) {
      setLoadingComplete(true);

      setTimeout(() => {
        setSlideAway(true);
      }, 6500);
    }
  }, [progress]);

  return (
    <>
      {!slideAway && (
        <Html fullscreen onClick={(e) => { e.stopPropagation() }}>
          <div onClick={(e) => { e.stopPropagation() }} className={`loader-container ${loadingComplete ? 'complete' : ''}`}>
            <img src={isMobile ? leftGateMobile : leftGate} alt='Left Gate' className={`gate left ${leftGateMobileClass}`} />
            <img src={isMobile ? rightGateMobile : rightGate} alt='Right Gate' className={`gate right ${rightGateMobileClass}`} />
            <h2 className={`progress-label ${loadingLabelMobileClass}`}>Loading {progress.toFixed(0)}%</h2>
            <img className={`loading-icon ${loadingIconMobileClass}`} alt="icon" src={loadingIcon} />
            <div className={`loading-bar-background ${loadingBarBackgroundMobileClass}`} style={{ width: '32.5vw' }}></div>
            <img className={`loading-bar ${loadingBarMobileClass}`} alt="bar" src={loadingBar} style={{ width: progress >= 100 ? '32.5vw' : `${progress}vw` }} />
          </div>
        </Html>
      )}
    </>
  );
}
