import React from "react";
import "./css/history.css";

import bg from "../../../assets/cosmiclabs/spaceship.png";
import line4 from "../../../assets/fullScreenLines/lines5.png";

export function History() {
  return (
    <>
      <img src={bg} alt="bg" className="bgSkies" />
      <img src={line4} alt="line4" className="line4Skies" />

      <h2 className="storyHeaderCS">STORY</h2>
      <div className="group-containerCS">
        <div className="rectangle rectangle-1"></div>
        <div className="rectangle rectangle-2"></div>
      </div>

      <div className="historyParaDivCosmic">
        <p className="historyPara">
          <span className="historyTitleSkies">HISTORY</span>
          <br></br>
          Cosmic Genesis is a Legacy NFT Collection <br></br>
          of 1,000 Galactic Ducks acquired by Chimba<br></br>
          Studios. With Chimba's expertise, we are excited to continue<br></br>
          developing this project by what the community knows it best for. Its
          art.<br></br>
          <br></br>
        </p>
      </div>
    </>
  );
}
