import { useRef, useState } from "react";
import { Canvas } from "@react-three/fiber";
import { Gltf, useCursor } from "@react-three/drei";

import { FrameDesktop } from "./FrameOriginal";
import { Rig } from "./Rig";

import {Control} from "../Models/Control"
import {Jet} from "../Models/Jet"
import {Racing} from "../Models/Racing"
import {Mansion} from "../Models/Mansion"
import {Zoe} from "../Models/Zoe"

export function Cards() {
  const [positionOfControl, setPositionOfControl] = useState([0, -1, -17]);
  const [positionOfMansion, setPositionOfMansion] = useState([0.6, -1, -1.4]);
  const [positionOfQuantum, setPositionOfQuantum] = useState([-2, -2, -3]);
  const [positionOfSkies, setPositionOfSkies] = useState([55, 0, -30]);
  const [positionOfRacing, setPositionOfRacing] = useState([-12.5, -2, -12]);

  const [hovered, setHovered] = useState(false);
  useCursor(hovered);
  
  const gamesFrame = useRef();
  const gamesRef = useRef();
  const passFrame = useRef();
  const passRef = useRef();
  const skiesFrame = useRef();
  const skiesRef = useRef();
  const racingFrame = useRef();
  const racingRef = useRef();
  const mansionFrame = useRef();
  const mansionRef = useRef();


  return (
    <Canvas
      camera={{ fov: 90, position: [0, 0, 20] }}
      
      eventSource={document.getElementById("root")}
      eventPrefix="client"
    >
      <color attach="background" args={["#fff"]} />
      <FrameDesktop
        id="Racing"
        bg="#e4cdac"
        position={[-2.3, 0, 0]}
        ref={racingFrame}
        referance={racingRef}
      >
        <ambientLight intensity={5} />
        <Racing
          position={positionOfRacing}
          rotation={[0.3, 1.6, 0]}
        />
      </FrameDesktop>

      <FrameDesktop
        id="Cursed"
        bg="#e4cdac"
        position={[1.15, 0, 0]}
        ref={mansionFrame}
        referance={mansionRef}
      >
        <ambientLight intensity={5} />
        <Mansion
          position={positionOfMansion}
          rotation={[0.1, -0.25, 0]}
        />
      </FrameDesktop>

      <FrameDesktop
        id="Pass"
        bg="#e4cdac"
        ref={gamesFrame}
        referance={gamesRef}
      >
        <ambientLight intensity={5} />
        <Control
          position={positionOfControl}
          rotation={[-0.2, 0, 0]}
        />
      </FrameDesktop>

      <FrameDesktop
        bg="#fff"
        id="Quantum"
        position={[-1.15, 0, 0]}
        name=""
        ref={passFrame}
        referance={passRef}
      >
        <ambientLight intensity={2.5} />
        <Zoe
          position={positionOfQuantum}
          rotation={[0, 0.6, 0]}
        />
      </FrameDesktop>

      <FrameDesktop
        id="Skies"
        bg="#000"
        position={[2.3, 0, 0]}
        ref={skiesFrame}
        referance={skiesRef}
      >
        <ambientLight intensity={3} />
        <Jet
          position={positionOfSkies}
          rotation={[0, -0.5, 0]}
        />
      </FrameDesktop>
      <Rig
        positionOfControl={positionOfControl}
        setPositionOfControl={setPositionOfControl}
        positionOfMansion={positionOfMansion}
        setPositionOfMansion={setPositionOfMansion}
        positionOfQuantum={positionOfQuantum}
        setPositionOfQuantum={setPositionOfQuantum}
        positionOfSkies={positionOfSkies}
        setPositionOfSkies={setPositionOfSkies}
        positionOfRacing={positionOfRacing}
        setPositionOfRacing={setPositionOfRacing}
      />
    </Canvas>
  );
}
