import React, { useEffect, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Gltf, Image, useCursor } from '@react-three/drei';
import { Frame } from './Frame';
import { Rig } from './Rig';
import rightArrow from '../../assets/mainInfo/RightArrow.webp';
import leftArrow from '../../assets/mainInfo/LeftArrow.webp';


import {Control} from "../Models/Control"
import {Jet} from "../Models/Jet"
import {Racing} from "../Models/Racing"
import {Mansion} from "../Models/Mansion"
import {Zoe} from "../Models/Zoe"

function useCounterRef(initialValue = 3, maxValue = 4) {
  const counterRef = useRef({ value: initialValue, maxValue });

  const updateValue = (value) => (value + (maxValue + 1)) % (maxValue + 1);

  const increment = () => {
    counterRef.current.value = updateValue(counterRef.current.value + 1);
  };

  const decrement = () => {
    counterRef.current.value = updateValue(counterRef.current.value - 1);
  };

  const reset = () => {
    counterRef.current.value = initialValue;
  };

  return { counterRef, increment, decrement, reset };
}

export function CardsMobile() {
  const [hovered, setHovered] = useState(false);
  useCursor(hovered);

  const [positionOfControl, setPositionOfControl] = useState([0, -1, -17]);
  const [positionOfSkies, setPositionOfSkies] = useState([0, 0, -15]);
  const gamesFrame = useRef();
  const gamesRef = useRef();
  const passFrame = useRef();
  const passRef = useRef();
  const skiesFrame = useRef();
  const skiesRef = useRef();
  const mansionFrame = useRef();
  const mansionRef = useRef();
  const { counterRef, increment, decrement } = useCounterRef(3, 4);
  const frames = [passFrame, gamesFrame, skiesFrame, mansionFrame];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const calculatePosition = (x) => (screenWidth > 600 ? x : x / 1.75);

  const rightArrowPosition = [calculatePosition(2), 0, -1];
  const leftArrowPosition = [calculatePosition(-2), 0, -1];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const triggerAnimation = (increaseOrDecrease) => {
    if (increaseOrDecrease) increment();
    else decrement();

    const movement = -25;
  
    const nextIndex = (counterRef.current.value + 1) % frames.length;
    const direction = increaseOrDecrease ? 1 : -1;
  
    // Hide IDs before starting the animation
    frames.forEach((frame) => frame.current?.hideID());
    frames.forEach((frame) => frame.current?.adjustPosition(0));
  
    // Set the initial position of "Pass" frame outside the animation loop
    frames[0].current?.adjustPosition(0);
  
    if (direction === 1) {
      frames[nextIndex].current?.adjustPosition(-movement);
    } else {
      frames[nextIndex].current?.adjustPosition(movement);
    }
  
    frames.forEach((frame, index) => {
      if (index === counterRef.current.value) {
        frame.current?.animatePosition({ x: movement * direction, y: 0, z: -0.02 }, 2, 'power2.inOut', () => {
          // Show IDs after the increment animation is complete
          frames.forEach((frame) => frame.current?.showID());
        });
      } else if (index === nextIndex) {
        frame.current?.animatePosition({ x: 0, y: 0, z: 0 }, 2, 'power2.inOut');
      } else {
        frame.current?.animatePosition({ x: movement * direction, y: 0, z: -0.01 }, 2, 'power2.inOut');
      }
    });
  };
  
  useEffect(() => {
    triggerAnimation(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Canvas camera={{ fov: 90, position: [0, 0, 20] }} eventSource={document.getElementById('root')} eventPrefix="client">
      <Image
        url={rightArrow}
        position={rightArrowPosition}
        onClick={() => triggerAnimation(true)}
        onPointerOver={(e) => { setHovered(true); e.stopPropagation(); }}
        onPointerLeave={() => setHovered(false)}
        scale={[0.25, 0.25, 0.25]}
      />
      <Image
        url={leftArrow}
        position={leftArrowPosition}
        onClick={() => triggerAnimation(false)}
        onPointerOver={(e) => { setHovered(true); e.stopPropagation(); }}
        onPointerLeave={() => setHovered(false)}
        scale={[0.25, 0.25, 0.25]}
      />

      <color attach="background" args={['#fff']} />
        <Frame id="Pass" bg="#e4cdac" ref={gamesFrame} referance={gamesRef}>

            <>
              <ambientLight intensity={5} />
              <Control position={positionOfControl} rotation={[-0.2, 0, 0]} />
            </>

        </Frame>

        <Frame bg="#fff" id="Quantum" ref={passFrame} referance={passRef}>

            <>
              <ambientLight intensity={2.5} />
              <Zoe position={[0, -2, -3]} />
            </>

        </Frame>
   
        <Frame id="Skies" bg="#000" ref={skiesFrame} referance={skiesRef}>

            <>
              <ambientLight intensity={3} />
              <Jet position={positionOfSkies} />
            </>

        </Frame> 

{/*         <Frame id="Racing" bg="#000" ref={racingFrame} referance={racingRef}>

            <>
              <ambientLight intensity={3} />
              <Gltf src={Racing} position={[0.8, -2, -11]} rotation={[0.3,1,0]} />
            </>

        </Frame> */}

        <Frame id="Cursed" bg="#000" ref={mansionFrame} referance={mansionRef}>

            <>
              <ambientLight intensity={3} />
              <Mansion position={[0.1, -1, -1]} />
            </>

        </Frame> 

      <Rig positionOfControl={positionOfControl} setPositionOfControl={setPositionOfControl} positionOfSkies={positionOfSkies} setPositionOfSkies={setPositionOfSkies} />
    </Canvas>
  );
}
