import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { useLocation, useRoute } from "wouter";
import { useEffect } from "react";
import { CameraControls } from "@react-three/drei";

export function Rig({
  position = new THREE.Vector3(0, 0, 2),
  focus = new THREE.Vector3(0, 0, 0),
  positionOfControl,
  setPositionOfControl,
  positionOfMansion,
  setPositionOfMansion,
  positionOfQuantum,
  setPositionOfQuantum,
  positionOfSkies,
  setPositionOfSkies,
  positionOfRacing,
  setPositionOfRacing,
}) {
  const { controls, scene } = useThree();
  const [, params] = useRoute("/page/:id");

  const location = useLocation();
  const cameraDistances = {
    minDistance: params?.id ? 0.001 : 2,
    maxDistance: 3 // Always 3
  };

  useEffect(() => {
    if(positionOfMansion !== undefined)
    {
      if (params?.id === "Pass") {
        if (positionOfControl[2] !== -11) setPositionOfControl([0, -1, -11]);
      } else if (positionOfControl[2] !== -17) setPositionOfControl([0, -1, -17]);
  
      if (params?.id === "Cursed") {
        if (positionOfMansion[0] !== 0.2) setPositionOfMansion([0.2, -1, -1.4]);
      } else if (positionOfMansion[0] !== 0.6)
        setPositionOfMansion([0.6, -1, -1.4]);
  
      if (params?.id === "Quantum") {
        if (positionOfQuantum[0] !== -1) setPositionOfQuantum([-1, -2, -3]);
      } else if (positionOfQuantum[0] !== -2) setPositionOfQuantum([-2, -2, -3]);
  
      if (params?.id === "Skies") {
        if (positionOfSkies[0] !== -20) setPositionOfSkies([-20, 0, 10]);
      } else if (positionOfSkies[0] !== 55) setPositionOfSkies([55, 0, -30]);
  
      if (params?.id === "Racing") {
        if (positionOfRacing[0] !== -0) setPositionOfRacing([0, -2, -12]);
      } else if (positionOfRacing[0] !== -12.5) setPositionOfRacing([-12.5, -2, -12]);
    }
    else{
      if (params?.id === "Games") {
        if (positionOfControl[2] !== -11) setPositionOfControl([0, -1, -11]);
      } else if (positionOfControl[2] !== -17) setPositionOfControl([0, -1, -17]);
      if (params?.id === "Skies") {
        if (positionOfSkies[2] !== 15) setPositionOfSkies([0, 0, 15]);
      } else if (positionOfSkies[2] !== -10) setPositionOfSkies([0, 0, -10]);
    }


  }, [
    params?.id,
    location,
    positionOfControl,
    setPositionOfControl,
    positionOfMansion,
    setPositionOfMansion,
    positionOfQuantum,
    setPositionOfQuantum,
    positionOfSkies,
    setPositionOfSkies,
    positionOfRacing,
    setPositionOfRacing
  ]);

  useEffect(() => {
    const active = scene.getObjectByName(params?.id);
    if (active) {
      active.parent.localToWorld(position.set(0, 0.5, 0.25));
      active.parent.localToWorld(focus.set(0, 0, -2));
    }
    controls?.setLookAt(...position.toArray(), ...focus.toArray(), true);
  }, [params?.id, scene, controls, position, focus]);

  return (
    <CameraControls
      makeDefault
      minAzimuthAngle={-Math.PI / 4}
      maxAzimuthAngle={Math.PI / 4}
      minPolarAngle={0.5}
      maxPolarAngle={Math.PI / 2}
      minDistance={cameraDistances.minDistance}
      maxDistance={cameraDistances.maxDistance}
    />
  );
}
