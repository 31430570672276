import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavSplitLine from "../assets/mainInfo/NavSplitLine.png";
import Burger from "../assets/mainInfo/HamburgerMenu.svg";
import CloseIcon from "../assets/mainInfo/closeIcon.svg";
import NavLine from "../assets/mainInfo/NavLine.png";
import chimbaLogoNav from "../assets/mainInfo/chimbaLogoNav.png";
import chimbaLogoNavWhite from "../assets/mainInfo/ChimbaLogoNavWhite.webp";
import "./NavBar.css";

export function NavBar({ route }) {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [burgerIcon, setBurgerIcon] = useState(Burger);
  const [bodyOverflow, setBodyOverflow] = useState("visible");
  const navigate = useNavigate();
  const [backgroundColorClass, setBackgroundColorClass] = useState("");
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    handleResize(); // Set initial screen size
    window.addEventListener("resize", handleResize);
    document.body.style.overflow = bodyOverflow;

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [bodyOverflow, isMenuOpen]);

  useEffect(() => {
    const getBackgroundColorClass = () => {
      if (route === "home") {
        return "transparentBackgroundHome";
      } else if (route === "pass") {
        const aRoute = document.querySelectorAll(".academy-route");
        aRoute.forEach(function (element) {
          if (!element.className.includes("PassNav"))
            element.style.color = "black";
        });
        return "transparentBackgroundHome";
      } else {
        return "transparentBackground";
      }
    };

    setBackgroundColorClass(getBackgroundColorClass());
  }, [route]);
  const handleBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
    setBurgerIcon(isMenuOpen ? Burger : CloseIcon);

    setBodyOverflow(isMenuOpen ? "visible" : "hidden");
  };
  const handleRouteClick = (targetRoute) => {
    navigate(`/${targetRoute}`);
  };

  return (
    <>
      {isSmallScreen && (
        <img
          src={burgerIcon}
          alt="Burger"
          className="Burger"
          onClick={handleBurgerClick}
        />
      )}

      {isMenuOpen && isSmallScreen && (
        <div className="fullscreen-menu">
          <img
            src={chimbaLogoNav}
            className="logoNavMobile"
            onClick={() => handleRouteClick("")}
          />
          <ul className="menuItems">
            <li className="menu-heading">PROJECTS</li>
            <li
              onClick={() => handleRouteClick("cursed-mansion")}
              className={route === "cursed-mansion" ? "selected" : ""}
            >
              Cursed Mansion
            </li>
            <li
              onClick={() => handleRouteClick("cyber-skies")}
              className={route === "cyber-skies" ? "selected" : ""}
            >
              Cyber Skies
            </li>
            <li
              onClick={() => handleRouteClick("quantum-breach")}
              className={route === "quantum-breach" ? "selected" : ""}
            >
              Quantum Breach
            </li>
            <li
              onClick={() => handleRouteClick("cosmic-labs")}
              className={route === "cosmic-labs" ? "selected" : ""}
            >
              Cosmic Labs
            </li>
            <li className={"greyed"}>Chimba Racing</li>
            <hr></hr>
            <li className="menu-heading">SUB-BRANDS</li>
            <li
              onClick={() => handleRouteClick("pass")}
              className={route === "pass" ? "selected" : ""}
            >
              Pass
            </li>
            <li
              onClick={() => handleRouteClick("academy")}
              className={route === "academy" ? "selected" : ""}
            >
              Academy
            </li>
            <li
              onClick={() => handleRouteClick("token")}
              className={route === "token" ? "selected" : ""}
            >
              Token
            </li>
            <hr></hr>
            <li className="menu-heading">MORE</li>
            <li
              onClick={() => handleRouteClick("about")}
              className={route === "about" ? "selected" : ""}
            >
              About
            </li>
            <li
              onClick={() => handleRouteClick("foundation")}
              className={route === "foundation" ? "selected" : ""}
            >
              Foundation
            </li>
            <li
              onClick={() => handleRouteClick("labs")}
              className={route === "labs" ? "selected" : ""}
            >
              Labs
            </li>
          </ul>
        </div>
      )}
      {!isSmallScreen && (
        <>
          <div className="sticky-nav-container">
            <div className="sticky-nav-content">
              <div className={`${backgroundColorClass}`}></div>
              {backgroundColorClass == "transparentBackground" && (
                <img
                  src={chimbaLogoNavWhite}
                  className="logoNav"
                  onClick={() => handleRouteClick("")}
                />
              )}
              {backgroundColorClass == "transparentBackgroundHome" && (
                <img
                  src={chimbaLogoNav}
                  className="logoNav"
                  onClick={() => handleRouteClick("")}
                />
              )}

              <img
                src={NavLine}
                alt="NavLine"
                className={!isSmallScreen ? "NavLine" : "hidden"}
              />
              <div className="dropdown">
                <h6
                  className={`GamesNav ${
                    route !== "home" ? "academy-route" : ""
                  }${
                    [
                      "quantum-breach",
                      "cursed-mansion",
                      "cosmic-labs",
                      "cyber-skies",
                      "chimba-racing",
                    ].includes(route)
                      ? " selected"
                      : ""
                  }`}
                >
                  PROJECTS{" "}
                  <i
                    className={`down ${
                      ["home", "pass"].includes(route)
                        ? " arrowBlack"
                        : "arrowWhite"
                    } `}
                  ></i>
                </h6>
                <div className="dropdown-content">
                  <a
                    className={`dropdown-item${
                      route === "cursed-mansion" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("cursed-mansion")}
                  >
                    Cursed Mansion
                  </a>
                  <a
                    className={`dropdown-item${
                      route === "cyber-skies" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("cyber-skies")}
                  >
                    Cyber Skies
                  </a>
                  <a
                    className={`dropdown-item${
                      route === "quantum-breach" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("quantum-breach")}
                  >
                    Quantum Breach
                  </a>

                  <a
                    className={`dropdown-item${
                      route === "cosmic-labs" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("cosmic-labs")}
                  >
                    Cosmic Labs
                  </a>
                  <a className={`dropdown-item greyed`}>
                    Chimba Racing
                  </a>
                </div>
              </div>
              <h6
                className={
                  (route !== "home" ? "PassNav academy-route" : "PassNav") +
                  (route === "pass" ? " selected" : "")
                }
                onClick={() => handleRouteClick("pass")}
              >
                PASS
              </h6>
              <h6
                className={
                  (route !== "home"
                    ? "AcademyNav academy-route"
                    : "AcademyNav") + (route === "academy" ? " selected" : "")
                }
                onClick={() => handleRouteClick("academy")}
              >
                ACADEMY
              </h6>

              <h6
                className={
                  (route !== "home" ? "TokenNav academy-route" : "TokenNav") +
                  (route === "token" ? " selected" : "")
                }
                onClick={() => handleRouteClick("token")}
              >
                TOKEN
              </h6>

              <div className="dropdownmore">
                <h6
                  className={`MoreNav ${
                    route !== "home" ? "academy-route" : ""
                  }${
                    ["foundation", "about", "labs", "contact"].includes(route)
                      ? " selected"
                      : ""
                  }`}
                >
                  MORE{" "}
                  <i
                    className={`down ${
                      ["home", "pass"].includes(route)
                        ? " arrowBlack"
                        : "arrowWhite"
                    } `}
                  ></i>
                </h6>
                <div className="dropdown-content">
                  <a
                    className={`dropdown-item${
                      route === "about" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("about")}
                  >
                    About
                  </a>
                  <a
                    className={`dropdown-item${
                      route === "foundation" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("foundation")}
                  >
                    Foundation
                  </a>
                  <a
                    className={`dropdown-item${
                      route === "labs" ? " selectedDrop" : ""
                    }`}
                    onClick={() => handleRouteClick("labs")}
                  >
                    Labs
                  </a>
                </div>
              </div>

              <img
                className={`split1 ${
                  ["home", "pass"].includes(route) ? "" : "splitChangeColor"
                }`}
                alt="line"
                src={NavSplitLine}
              ></img>
              <img
                className={`split2 ${
                  ["home", "pass"].includes(route) ? "" : "splitChangeColor"
                }`}
                alt="line"
                src={NavSplitLine}
              ></img>
              <img
                className={`split3 ${
                  ["home", "pass"].includes(route) ? "" : "splitChangeColor"
                }`}
                alt="line"
                src={NavSplitLine}
              ></img>
              <img
                className={`split4 ${
                  ["home", "pass"].includes(route) ? "" : "splitChangeColor"
                }`}
                alt="line"
                src={NavSplitLine}
              ></img>
            </div>
          </div>
        </>
      )}
    </>
  );
}
