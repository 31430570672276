import React from 'react';
import './Css/nft.css'

import background from '../../../assets/cursedMansion/bg4.png'
import line7 from '../../../assets/fullScreenLines/lines4.png'
import separator from '../../../assets/cursedMansion/separator2.png'
import nftHammer from '../../../assets/cursedMansion/nftHammer.png'
import nftPlaceholder from '../../../assets/cursedMansion/nftPlaceholder.png'

export function NFT() {

    return (
        <>
            <img src={background} alt='background' className='background4Cursed'></img>

            <img src={line7} alt='line7' className='line7Cursed'></img>

            <h2 className='nftHeaderCB'>NFTs</h2>
            <div className="group-containerNCB">
                <div className="rectangle rectangle-1NCB"></div>
                <div className="rectangle rectangle-2NCB"></div>
            </div>


            <img src={nftHammer} alt='nftHammer' className='nftHammerCursed'></img>
            <img src={nftPlaceholder} alt='nftPlaceholder' className='nftPlaceholderCursed'></img>   
            <span className='nftTitleCursed'>ITEMS</span>
            <p className='nftParaCursed'>
            Delve into Cursed Mansion's cryptic corners to uncover NFTs that bind power to your persona.<br/>
            Collect, trade, and utilize items for an edge against the darkness.
            </p>

            <img src={separator} alt='separator4' className='separator4'></img>
        </>
    )
};
