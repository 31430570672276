
import './Css/founder.css'
import { useState,useEffect } from 'react'

import founderBackground from '../../assets/about/founderBG.png'
import founderLine from '../../assets/about/founderLine.png'
import teamHeader from '../../assets/about/teamHeader.png'
import founderBGText from '../../assets/about/founderBGText.png'
import founderChimbaLogo from '../../assets/about/founderChimbaLogo.png'

import founderLine2 from '../../assets/about/founderLine2.png'
import separate from '../../assets/about/separate.png'
import rightArrow from '../../assets/about/rightArrow.png'
import leftArrow from '../../assets/about/leftArrow.png'


import oscar from '../../assets/team/oscar-03.webp'
import castell from '../../assets/team/castell-03.webp'
import kath from '../../assets/team/kath-03.webp'
import davis from '../../assets/team/davis-03.webp'
import rq from '../../assets/team/roni-03.webp'
import alejo from '../../assets/team/alejo-03.webp'
import alfredo from '../../assets/team/alfredo-03.webp'
import bañol from '../../assets/team/bañol-03.webp'
import carlos from '../../assets/team/carlos-03.webp'
import checho from '../../assets/team/checho-03.webp'
import johan from '../../assets/team/johan-03.webp'
import juanda from '../../assets/team/juanda-03.webp'
import juanma from '../../assets/team/juanma-03.webp'
import orly from '../../assets/team/orly-03.webp'
import rafa from '../../assets/team/rafa-03.webp'
import simon from '../../assets/team/simon-03.webp'
import suikan from '../../assets/team/suikan-03.webp'
import tato from '../../assets/team/tato-03.webp'



export function Founder() {
    // States for first row
    const [firstRowOne, setFirstRowOne] = useState(oscar);
    const [firstRowTwo, setFirstRowTwo] = useState(castell);
    const [firstRowThree, setFirstRowThree] = useState(kath);

    // States for second row
    const [secondRowOne, setSecondRowOne] = useState(davis);
    const [secondRowTwo, setSecondRowTwo] = useState(rq);
    const [secondRowThree, setSecondRowThree] = useState(alejo);

    const [index, setIndex] = useState(0);

    const changeTeamImages = (increaseOrDecrease) => {
        if (increaseOrDecrease === "increase") {
            setIndex(prevIndex => (prevIndex + 1) % 3); // Adjusted for 3 sets of 6 team members
        } else {
            setIndex(prevIndex => (prevIndex - 1 + 3) % 3); // Adjusted for 3 sets of 6 team members
        }
    };
    
    useEffect(() => {
        if (index === 0) {
            // First set of 6
            setFirstRowOne(oscar);
            setFirstRowTwo(castell);
            setFirstRowThree(rq);
            setSecondRowOne(kath);
            setSecondRowTwo(davis);
            setSecondRowThree(alejo);
        } else if (index === 1) {
            // Second set of 6
            setFirstRowOne(alfredo);
            setFirstRowTwo(bañol);
            setFirstRowThree(carlos);
            setSecondRowOne(checho);
            setSecondRowTwo(johan);
            setSecondRowThree(juanda);
        } else if (index === 2) {
            // Third set of 6
            setFirstRowOne(juanma);
            setFirstRowTwo(orly);
            setFirstRowThree(rafa);
            setSecondRowOne(simon);
            setSecondRowTwo(suikan);
            setSecondRowThree(tato);
        }
    }, [index]);

    return(
        <>
            <img src={founderBackground} alt='founderBackground' className='founderBackground'></img>
            <img src={founderLine} alt='founderLine' className='founderLine'></img>
            <img src={teamHeader} alt='teamHeader' className='teamHeader'></img>
            <img src={founderBGText} alt='founderBGText' className='founderBGText'></img>
            <img src={founderChimbaLogo} alt='founderChimbaLogo' className='founderChimbaLogo'></img>
            <img src={founderLine2} alt='founderLine2' className='founderLine2'></img>

            <img src={rightArrow} alt='rightArrowTeam' onClick={()=>{changeTeamImages("increase")}} className='rightArrowTeam'></img>
            <img src={leftArrow} alt='leftArrowTeam' onClick={()=>{changeTeamImages("decrease")}} className='leftArrowTeam'></img>

            {/* First Row */}
            <img src={firstRowOne} alt='team' className='team firstImage firstRow'></img>
            <img src={separate} alt='separate' className='separate firstRow'></img>
            <img src={firstRowTwo} alt='team' className='team secondImage firstRow'></img>
            <img src={separate} alt='separate' className='separate2 firstRow'></img>
            <img src={firstRowThree} alt='team' className='team thirdImage firstRow'></img>

            {/* Second Row */}
            <img src={secondRowOne} alt='team' className='team firstImage secondRow'></img>
            <img src={separate} alt='separate' className='separate secondRow'></img>
            <img src={secondRowTwo} alt='team' className='team secondImage secondRow'></img>
            <img src={separate} alt='separate' className='separate2 secondRow'></img>
            <img src={secondRowThree} alt='team' className='team thirdImage secondRow'></img>
        </>
    )
}