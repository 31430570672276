import React from 'react';
import './Css/token3.css'

import background from '../../assets/token/background3.png'
import greyLogoToken from '../../assets/token/greyLogo.png'
import line7 from '../../assets/token/line7.png'
import line8 from '../../assets/token/line8.png'

import eco1 from '../../assets/token/eco1.png'
import eco2 from '../../assets/token/eco2.png'
import eco3 from '../../assets/token/eco3.png'
import eco4 from '../../assets/token/eco4.png'

import eco5 from '../../assets/token/eco5.png'
import eco6 from '../../assets/token/eco6.png'
import eco7 from '../../assets/token/eco7.png'
import eco8 from '../../assets/token/eco8.png'

import chimbaTokenWhite from '../../assets/token/chimbaTokenWhite.png'
import chimbaTokenWhiteBg from '../../assets/token/chimbaTokenWhiteBg.png'

import ecosystemTitle from '../../assets/token/ecosystemTitle.png'
export function Token3() {

    return(
        <>
            <img src={background} alt='background' className='tokenBackground3'></img>
            <img src={greyLogoToken} alt='greyLogoToken' className='greyLogoToken'></img>
            <img src={line7} alt='line7' className='line7Token'></img>
            <img src={line8} alt='line8' className='line8Token'></img>

            <img src={eco1} alt='eco1' className='eco1'></img>
            <img src={eco2} alt='eco2' className='eco2'></img>
            <img src={eco3} alt='eco3' className='eco3'></img>
            <img src={eco4} alt='eco4' className='eco4'></img>

            <img src={eco5} alt='eco5' className='eco5'></img>
            <img src={eco6} alt='eco6' className='eco6'></img>
            <img src={eco7} alt='eco7' className='eco7'></img>
            <img src={eco8} alt='eco8' className='eco8'></img>

            <img src={chimbaTokenWhite} alt='chimbaTokenWhite' className='chimbaTokenWhite'></img>
            <img src={chimbaTokenWhiteBg} alt='chimbaTokenWhiteBg' className='chimbaTokenWhiteBg'></img>
            <img src={ecosystemTitle} alt='ecosystemTitle' className='ecosystemTitle'></img>
            <p className='ecosystemPara'>
                The Chimba Token is the heart of a diverse digital landscape, unifying a world of games and projects.<br></br>
                It's the currency of our creative frontier.
            </p>
        </>
    )
};