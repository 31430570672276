import * as THREE from "three";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLocation, useRoute } from "wouter";
import { useCursor, MeshPortalMaterial, Text } from "@react-three/drei";
import { suspend } from "suspend-react";
import { useFrame, extend } from "@react-three/fiber";
import { easing, geometry } from "maath";
import { gsap } from "gsap";

extend(geometry);
const Thuner = import("../../assets/fonts/Thuner.ttf");

export const FrameDesktop = forwardRef(
  (
    {
      id,
      name,
      author,
      bg,
      width = 1.1,
      height = 1.61803398875,
      children,
      referance,
      smallWindow,
      ...props
    },
    ref
  ) => {
    const portal = useRef();
    const [, setLocation] = useLocation();
    const [, params] = useRoute("/page/:id");
    const [hovered, hover] = useState(false);
    const [isIDHidden, setIDHidden] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [touchStartTime, setTouchStartTime] = useState(0);

    useCursor(hovered);
    useFrame((state, dt) =>
      easing.damp(portal.current, "blend", params?.id === id ? 1 : 0, 0.2, dt)
    );

    const handleTouchStart = () => {
      const now = Date.now();
      const DOUBLE_TAP_THRESHOLD = 300;

      if (now - touchStartTime < DOUBLE_TAP_THRESHOLD) {
        setLocation("/page/" + id);
      } else {
        setTouchStartTime(now);
      }
    };

    const animatePosition = (targetPosition, duration, ease) => {
      gsap.to(referance.current.position, {
        x:
          targetPosition.x !== undefined
            ? targetPosition.x
            : referance.current.position.x,
        y:
          targetPosition.y !== undefined
            ? targetPosition.y
            : referance.current.position.y,
        z:
          targetPosition.z !== undefined
            ? targetPosition.z
            : referance.current.position.z,
        duration: duration || 2,
        ease: ease || "power2.inOut",
      });
    };

    const hideFrame = () => {
      setIsVisible(false);
    };
    const showFrame = () => {
      setIsVisible(true);
    };

    const adjustPosition = (targetXPosition) => {
      referance.current.position.x = targetXPosition;
    };

    const hideID = () => {
      setIDHidden(false);
    };

    const showID = () => {
      setIDHidden(false);
    };

    useEffect(() => {
      // Animate the position using GSAP when the reference changes
      if (referance && referance.current && smallWindow) {
        if (referance.current.children[3].name === "Games")
          animatePosition({ x: 0, y: 0, z: 0 }, 0);
        if (referance.current.children[3].name === "Quantum")
          animatePosition({ x: -1.15, y: 0, z: 0 }, 0);
        if (referance.current.children[3].name === "Skies")
          animatePosition({ x: 1.15, y: 0, z: 0 }, 0);
        if (referance.current.children[3].name === "Racing")
          animatePosition({ x: 25, y: 0, z: 0 }, 0);
        if (referance.current.children[3].name === "Cursed")
          animatePosition({ x: 25, y: 0, z: 0 }, 0);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      animatePosition,
      adjustPosition,
      hideID,
      showID,
      hideFrame,
      showFrame,
    }));

    return (
      <group ref={referance} {...props} visible={isVisible}>
        <Text
          font={suspend(Thuner).default}
          fontSize={0.11}
          anchorY="top"
          anchorX="left"
          color="#FCB802"
          lineHeight={0.8}
          position={[-0.44, 0.715, 0.01]}
          material-toneMapped={false}
          visible={!isIDHidden}
        >
          {name}
        </Text>
        <Text
          font={suspend(Thuner).default}
          fontSize={0.1}
          anchorX="center"
          anchorY="center"
          position={[0, -0.6, 0.01]}
          visible={!isIDHidden}
          material={
            new THREE.MeshBasicMaterial({
              color: new THREE.Color(0xffffff),
              toneMapped: false,
              transparent: true,
              depthWrite: false,
            })
          }
          renderOrder={2}
          outlineWidth={0.015}
          outlineColor="black"
          outlineOpacity={1}
          outlineBlur={0}
        >
          {id}
        </Text>
        <Text
          font={suspend(Thuner).default}
          fontSize={0.04}
          anchorX="right"
          position={[0.0, -0.677, 0.01]}
          material-toneMapped={false}
          visible={!isIDHidden}
        >
          {author}
        </Text>
        <mesh
          name={id}
          onDoubleClick={(e) => (
            e.stopPropagation(), setLocation("/page/" + e.object.name)
          )}
          onPointerOver={(e) => {
            hover(true);
            showID();
          }}
          onPointerOut={() => {
            hover(false);
            hideID();
          }}
          onPointerDown={(e) => {
            e.stopPropagation();
            if (e.pointerType === "touch") {
              handleTouchStart();
            }
          }}
        >
          <roundedPlaneGeometry args={[width, height, 0.1]} />
          <MeshPortalMaterial
            ref={portal}
            events={params?.id === id}
            side={THREE.DoubleSide}
          >
            <color attach="background" args={[bg]} />
            {children}
          </MeshPortalMaterial>
        </mesh>
      </group>
    );
  }
);
