import React, { useEffect } from "react";
import { NavBar } from "../NavBar";

import "./Css/labs.css";
//Section one
import line1 from "../../assets/foundation/line1.png";
import line2 from "../../assets/foundation/line2.png";
import line3 from "../../assets/foundation/line3.png";
import scrollToNav from "../../assets/foundation/scrollToNav.png";
import mouse from "../../assets/foundation/mouse.png";

import labsHeader from "../../assets/labs/labsHeader.png";
import belowHeader from "../../assets/labs/belowHeader.png";
import logo from "../../assets/labs/chimbaBlue.png";
import blueBlock from "../../assets/labs/blueBlock.png";
import pinkTextBlock from "../../assets/labs/blueTextBlock.png";
import stayUpdated from "../../assets/foundation/stayUpdated.png";

//Section two
import chimbaLogoColor from "../../assets/labs/chimbaLogoColor.png";
import line4 from "../../assets/foundation/line4.png";
import line5 from "../../assets/foundation/line5.png";
import line6 from "../../assets/foundation/line6.png";
import lineAccent from "../../assets/foundation/lineAccent.png";
import firstHeader from "../../assets/foundation/lorem.png";
import secondHeader from "../../assets/foundation/ipsum.png";
import Footer from "../Footer";

export function Labs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <NavBar route="labs" />

      <img src={line1} alt="line1" className="line1F" />
      <img src={line2} alt="line2" className="line2F" />
      <img src={line3} alt="line3" className="line3F" />
      <img src={scrollToNav} alt="scrollToNav" className="scrollToNavF" />
      <img src={mouse} alt="mouse" className="mouseF" />
      <img src={labsHeader} alt="labsHeader" className="labsHeader" />
      <img src={belowHeader} alt="belowHeader" className="belowHeader"></img>
      <img src={logo} alt="logo" className="chimbaPink" />
      <img src={blueBlock} alt="pinkBlock" className="pinkBlock" />
      <img src={pinkTextBlock} alt="pinkTextBlock" className="pinkTextBlock" />
      <img src={stayUpdated} alt="stayUpdated" className="stayUpdated" onClick={()=>window.open("https://x.com/chimbastudio")} />
      {/* <p className="foundationPara">
        Chimba Foundation empowers communities with <br></br>
        &nbsp;&nbsp;&nbsp;tech donations, enabling access to the digital world.
        <br></br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We bridge gaps and nurture
        potential.
      </p> */}

      <img
        src={chimbaLogoColor}
        alt="chimbaLogoColor"
        className="chimbaLogoColor"
      />
      <img src={line4} alt="line4" className="line4F" />
      <img src={line5} alt="line5" className="line5F" />
      <img src={line6} alt="line6" className="line6F" />
      <img src={lineAccent} alt="lineAccent" className="lineAccent" />

      <h2 className="labsHeader1">LAUNCHPAD</h2>
      <div className="group-containerLH1">
        <div className="rectangle rectangle-1labs"></div>
        <div className="rectangle rectangle-2labs"></div>
      </div>
      <p className="firstHPF">
        Chimba Labs serves as a launchpad for pioneering <br></br>
        projects that shape the future of Web3, infusing our <br></br>
        digital realm with groundbreaking innovations.
      </p>

      <h2 className="labsHeader2">LEVERAGE</h2>
      <div className="group-containerLH2">
        <div className="rectangle rectangle-1labs2"></div>
        <div className="rectangle rectangle-2labs2"></div>
      </div>
      <p className="secondHPF">
        We leverage cutting-edge technology to ensure our developments<br></br>
        not only advance but also positively impact the Web3 ecosystem.
      </p>

      <Footer height={"94vw"} />
    </>
  );
}
