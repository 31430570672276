import React from 'react';
import { NavBar } from '../../NavBar';

import './Css/waifus.css'


import line1 from '../../../assets/fullScreenLines/QB/lines1.png'

import scrollToNav from '../../../assets/waifus/scrollToNav.png'
import mouse from '../../../assets/waifus/mouse.png'

import quantumLogo from '../../../assets/waifus/quantumLogo.png'
import playButton from '../../../assets/waifus/playButton2.png'


import { History } from './History';
import { Characters } from './Characters';
import Footer from '../../Footer';
import { Virus } from './Virus';
import { World } from './World';
import { useEffect } from 'react';

export function Waifus() {

    const openVideo = () =>{
        window.open("https://youtu.be/t-VViRgxzOQ", "_blank");
    }   
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <>
            <NavBar route="quantum-breach"/>

            <video className="waifuBackground" autoPlay muted loop>
                    <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/Zoe.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>

            <img src={line1} alt="line1" className="line1Waifu" />

            <img src={scrollToNav} alt="scrollToNav" className="scrollToNavWaifu" />
            <img src={mouse} alt="mouse" className="mouseWaifu" />

            <h2 className='mainHeaderQBMain'>QUANTUM BREACH</h2>
            <div className="group-containerQBMain">
                <div className="rectangle rectangle-1QBMain"></div>
                <div className="rectangle rectangle-2QBMain"></div>
            </div>
            <img src={quantumLogo} alt="quantumLogo" className="quantumLogo" />
            <img src={playButton} alt='playButton' className='playButtonQuantum' onClick={openVideo}></img>

            <History/>
            <Characters/>
            <Virus/>
            <World/>
            <Footer height={"278vw"}/>
            
        </>
    )
};