import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import jetModel from '../../assets/3dModels/Jet.glb' 

export function Jet(props) {
  const { nodes, materials } = useGLTF(jetModel)
  return (
    <group {...props} dispose={null}>
      <group position={[1.067, -0.891, -71.073]} rotation={[0.535, -0.213, -0.12]}>
        <mesh geometry={nodes.SciFi_Fighter_1.geometry} material={materials.SciFi_Fighter_AK5} />
        <mesh geometry={nodes.SciFi_Fighter_2.geometry} material={materials['Material.002']} />
      </group>
      <group position={[4.953, -7.863, -32.118]} rotation={[1.819, 0.552, -0.869]} scale={0.019}>
        <mesh geometry={nodes.Su54_OBJ_1.geometry} material={materials['Material.001']} />
        <mesh geometry={nodes.Su54_OBJ_2.geometry} material={materials['Material.003']} />
      </group>
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.005']} position={[4.147, 4.998, -71.784]} rotation={[-0.716, -1.416, -2.091]} scale={2.833} />
      <mesh geometry={nodes.Plane004.geometry} material={materials['Material.004']} position={[-7.064, -2.795, -64.154]} rotation={[0.583, 1.391, -0.007]} scale={[-7.363, -7.363, -1.984]} />
      <mesh geometry={nodes.Plane005.geometry} material={materials['Material.006']} position={[12.503, -2.024, -29.207]} rotation={[1.928, 0.661, -2.494]} scale={[-7.363, -7.363, -1.244]} />
      <mesh geometry={nodes.Plane010.geometry} material={materials['Material.009']} position={[-2.924, -6.144, -48.532]} rotation={[0.458, -0.347, 0.483]} scale={19.23} />
      <mesh geometry={nodes.Sphere.geometry} material={materials.Material} position={[-9.333, -10.966, -52.142]} rotation={[2.628, 0.072, 2.964]} scale={[95.136, 84.163, 141.749]} />
    </group>
  )
}

useGLTF.preload('../../assets/3dModels/Jet.glb')