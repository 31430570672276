import React, { useEffect, useRef } from 'react';
import { NavBar } from '../../NavBar';

import './css/cosmic.css'

import line1 from '../../../assets/fullScreenLines/lines1.png'
import Footer from '../../Footer';
import playButton from '../../../assets/waifus/playButton2.png'
import { History } from './history';
import { Characters } from './Characters';


export function Cosmic() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;
        window.scrollTo(0, 0);
        // Start playing the video muted
        video.muted = true;
        video.play().catch(error => {
            // Autoplay was prevented
            console.log('Autoplay was prevented:', error);
        });
        if (window.innerWidth > 1000) {
            window.scrollBy(0, window.innerWidth * 0.05);
        }
    }, []);

    // const openVideo = () =>{
    //     window.open("https://youtu.be/I2K-rW7zia8", "_blank");
    // } 


    return (
        <>
            <NavBar route="cosmic-labs"/>

            <video ref={videoRef} className='cosmicBackground' loop muted autoPlay >
                <source src="https://chimbacomingsoon.s3.us-east-2.amazonaws.com/ChimbaCosmicLabs.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

            <img src={line1} alt="line1" className="line1Skies" />
            <h2 className='mainHeaderCosmic'>COSMIC LABS</h2>
            <div className="group-containerCosmic">
                <div className="rectangle rectangle-1Cosmic"></div>
                <div className="rectangle rectangle-2Cosmic"></div>
            </div>
            {/* <img src={playButton} alt='playButton' className='playButtonQuantum' onClick={openVideo}></img> */}

            <History/>
            <Characters/>
            <Footer height={"163.5vw"}/>
        </>
    )
};
