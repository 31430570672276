import { useEffect, useRef, useState } from 'react'
import { Canvas } from '@react-three/fiber'
import { Gltf, Image, useCursor } from '@react-three/drei'

import { FrameDesktop } from './FrameOriginal'
import { Rig } from './Rig'
import rightArrow from '../../assets/mainInfo/RightArrow.webp';
import leftArrow from '../../assets/mainInfo/LeftArrow.webp';

import {Control} from "../Models/Control"
import {Jet} from "../Models/Jet"
import {Racing} from "../Models/Racing"
import {Mansion} from "../Models/Mansion"
import {Zoe} from "../Models/Zoe"

function useCounterRef(initialValue = 0, maxValue = 1) {
  const counterRef = useRef({ value: initialValue, maxValue });

  const updateValue = (value) => (value + (maxValue + 1)) % (maxValue + 1);

  const increment = () => {
    counterRef.current.value = updateValue(counterRef.current.value + 1);
  };

  const decrement = () => {
    counterRef.current.value = updateValue(counterRef.current.value - 1);
  };

  const reset = () => {
    counterRef.current.value = initialValue;
  };

  return { counterRef, increment, decrement, reset };
}


export function CardsSmall()
{
    const [positionOfControl, setPositionOfControl] = useState([0, -1, -17]);
    const [positionOfSkies, setPositionOfSkies] = useState([0, 0, -10]);
    const { counterRef, increment, decrement } = useCounterRef(0,1);
    const [hovered, setHovered] = useState(false);
    useCursor(hovered);

    const gamesFrame = useRef();
    const gamesRef = useRef();
    const passFrame = useRef();
    const passRef = useRef();
    const skiesFrame = useRef();
    const skiesRef = useRef();
    const racingFrame = useRef();
    const racingRef = useRef();
    const mansionFrame = useRef();
    const mansionRef = useRef();

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const calculatePosition = (x) => (screenWidth > 600 ? x : x / 1.75);
    const rightArrowPosition = [calculatePosition(2.3), 0, -0.25];
    const leftArrowPosition = [calculatePosition(-2.3), 0, -0.25];

    const frames = [gamesFrame, passFrame, skiesFrame];
    const frames2 = [racingFrame, mansionFrame];

    const triggerAnimation = async (increaseOrDecrease) => {
      if (increaseOrDecrease) increment();
      else decrement();
    
      // Hide IDs before starting the animation
      frames.forEach((frame) => frame.current?.hideID());
      frames2.forEach((frame) => frame.current?.hideID());
    
      // Check the value of counterRef.current.value
      if (counterRef.current.value === 0) {
        // If the value is 0, show frames
        frames.forEach((frame, index) => {
          var xPosition;
          if (index === 0) xPosition = 0;
          if (index === 1) xPosition = -1.15;
          if (index === 2) xPosition = 1.15;
    
          frame.current?.animatePosition({ x: xPosition, y: 0, z: 0 }, 2, 'power2.inOut');
          frame.current?.showFrame();
        });
    
        frames2.forEach(async (frame) => {
          frame.current?.animatePosition({ x: 25, y: 0, z: 0 }, 2, 'power2.inOut');
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
          frame.current?.hideFrame();
        });
      } else if (counterRef.current.value === 1) {
        frames2.forEach((frame, index) => {
          var xPosition;
          if (index === 0) xPosition = -0.75;
          if (index === 1) xPosition = 0.75;
          frame.current?.animatePosition({ x: xPosition, y: 0, z: 0 }, 2, 'power2.inOut');
          frame.current?.showFrame();
        });
        frames.forEach(async (frame) => {
          frame.current?.animatePosition({ x: 25, y: 0, z: 0 }, 2, 'power2.inOut');
          await new Promise(resolve => setTimeout(resolve, 2000)); // Wait for 2 seconds
          frame.current?.hideFrame();
        });
      }
    };
    
    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return (
        <Canvas camera={{ fov: 90, position: [0, 0, 20] }} eventSource={document.getElementById('root')} eventPrefix="client">
        
        <Image
          url={rightArrow}
          position={rightArrowPosition}
          onClick={() => triggerAnimation(true)}
          onPointerOver={(e) => { setHovered(true); e.stopPropagation(); }}
          onPointerLeave={() => setHovered(false)}
          scale={[0.25, 0.25, 0.25]}
        />
        <Image
          url={leftArrow}
          position={leftArrowPosition}
          onClick={() => triggerAnimation(false)}
          onPointerOver={(e) => { setHovered(true); e.stopPropagation(); }}
          onPointerLeave={() => setHovered(false)}
          scale={[0.25, 0.25, 0.25]}
        />

        <color attach="background" args={['#fff']} />
        <FrameDesktop id="Racing" smallWindow={true} bg="#e4cdac" position={[-1.15, 0, 0]} rotation={[0, 0.2, 0]} ref={racingFrame} referance={racingRef}>
          <ambientLight intensity={5} />
          <Racing position={[-0.6, -2, -11]} rotation={[0.3,1,0]} />
        </FrameDesktop>

        <FrameDesktop id="Cursed" smallWindow={true} bg="#e4cdac" position={[1.15, 0, 0]} rotation={[0, -0.2, 0]} ref={mansionFrame} referance={mansionRef}>
          <ambientLight intensity={5} />
          <Mansion position={[0.3, -1, -1.4]} />
        </FrameDesktop>

        <FrameDesktop id="Pass" smallWindow={true} bg="#e4cdac"   ref={gamesFrame} referance={gamesRef}>
          <ambientLight intensity={5} />
          <Control  position={positionOfControl} rotation={[-0.2, 0, 0]} />
        </FrameDesktop>

        <FrameDesktop bg="#fff" smallWindow={true} id="Quantum" position={[-1.15, 0, 0]} rotation={[0, 0.5, 0]} name="" ref={passFrame} referance={passRef}>
          <ambientLight intensity={2.5} />
          <Zoe position={[0, -2, -3]} />
        </FrameDesktop>

        <FrameDesktop id="Skies" smallWindow={true} bg="#000" position={[1.15, 0, 0]} rotation={[0, -0.5, 0]} ref={skiesFrame} referance={skiesRef}>
          <ambientLight intensity={3} />
          <Jet position={positionOfSkies} />
        </FrameDesktop>
        <Rig positionOfControl={positionOfControl} setPositionOfControl={setPositionOfControl} positionOfSkies={positionOfSkies} setPositionOfSkies={setPositionOfSkies} />
      </Canvas>
    )
}