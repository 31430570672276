import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import mansionModel from '../../assets/3dModels/Mansion.glb' 

export function Mansion(props) {
  const { nodes, materials } = useGLTF(mansionModel)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Sphere.geometry} material={materials['Material.005']} />
      <mesh geometry={nodes.Plane.geometry} material={materials['Material.006']} />
      <mesh geometry={nodes.Diorama_corregido_1.geometry} material={materials.teeth} />
      <mesh geometry={nodes.Diorama_corregido_2.geometry} material={materials.mansion} />
      <mesh geometry={nodes.Diorama_corregido_3.geometry} material={materials.Mesa} />
      <mesh geometry={nodes.Diorama_corregido_4.geometry} material={materials.skull} />
      <mesh geometry={nodes.Diorama_corregido_5.geometry} material={materials.cortinas_y_candelabro} />
      <mesh geometry={nodes.Diorama_corregido_6.geometry} material={materials['Material.003']} />
    </group>
  )
}

useGLTF.preload('../../assets/3dModels/Mansion.glb')