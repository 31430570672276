// PassInfo.js
import React, { useState, useEffect } from "react";
import "./Css/passInfo.css";
import backgroundPass from "../../assets/pass/backgroundPass.webp";

import { AccessAcademy } from "./accessAcademy";
import { ChimbaTokens } from "./chimbaTokens";
import { updateStyles } from "./passFunctions.js";
import { Nfts } from "./nfts";
import { ExclusiveEvents } from "./exclusiveEvents";
import { EarlyAccess } from "./earlyAccess";
import { Behind } from "./behind";

export function PassInfo() {
  const scrollThreshold = 50;

  const [isClickAndHold, setIsClickAndHold] = useState(false);

  useEffect(() => {
    const tvVisiblity = document.getElementById("tvVisiblity");
    const chestVisibility = document.getElementById("chestVisibility");
    const nftVisibility = document.getElementById("nftVisibility");
    const exclusiveEventsVisibility = document.getElementById(
      "exclusiveEventsVisibility"
    );
    const earlyVisibility = document.getElementById("earlyVisibility");
    const behindVisibility = document.getElementById("behindVisibility");

    const attention = document.getElementById("attention");
    const attentionChest = document.getElementById("attentionChest");
    const attentionNft = document.getElementById("attentionNft");
    const attentionExclusiveEvents = document.getElementById(
      "attentionExclusiveEvents"
    );
    const attentionEarly = document.getElementById("attentionEarly");
    const attentionBehind = document.getElementById("attentionBehind");

    const backgroundPassElement = document.getElementById("backgroundPass");
    const clickAndHoldText = document.getElementById("clickAndHoldText")

    const handleWheel = (event) => {
      if (isClickAndHold) {
        return; // Do nothing during click-and-hold
      }

      if (event.deltaY > scrollThreshold) {
        updateStyles("0%", 1);
        backgroundPassElement.style.filter = "grayscale(100%)";
        setTimeout(() => {
          tvVisiblity.style.display = "block";
          chestVisibility.style.display = "block";
          nftVisibility.style.display = "block";
          exclusiveEventsVisibility.style.display = "block";
          earlyVisibility.style.display = "block";
          behindVisibility.style.display = "block";

          attention.style.display = "block";
          attentionChest.style.display = "block";
          attentionNft.style.display = "block";
          attentionExclusiveEvents.style.display = "block";
          attentionEarly.style.display = "block";
          attentionBehind.style.display = "block";
          clickAndHoldText.style.display = "block";
        }, 2100);
      } else if (event.deltaY < -scrollThreshold) {
        updateStyles("60%", 50);
        tvVisiblity.style.display = "none";
        chestVisibility.style.display = "none";
        nftVisibility.style.display = "none";
        exclusiveEventsVisibility.style.display = "none";
        earlyVisibility.style.display = "none";
        behindVisibility.style.display = "none";

        attention.style.display = "none";
        attentionChest.style.display = "none";
        attentionNft.style.display = "none";
        attentionExclusiveEvents.style.display = "none";
        attentionEarly.style.display = "none";
        attentionBehind.style.filter = "none";
        backgroundPassElement.style.filter = "none";
        clickAndHoldText.style.display = "none"
      }
    };

    window.addEventListener("wheel", handleWheel);

    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, [isClickAndHold]);

  return (
    <>
      <img src={backgroundPass} alt="backgroundPass" id="backgroundPass" />
      <p id="clickAndHoldText">
        Explore dynamic content by hovering over pulsing circles,
        each representing a unique section. Hold down to dive
        deeper, uncovering exclusive insight.
      </p>
      <AccessAcademy
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
      <ChimbaTokens
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
      <Nfts
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
      <ExclusiveEvents
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
      <EarlyAccess
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
      {/* <Exclusive setIsClickAndHold={setIsClickAndHold} isClickAndHold={isClickAndHold}/> */}
      <Behind
        setIsClickAndHold={setIsClickAndHold}
        isClickAndHold={isClickAndHold}
      />
    </>
  );
}
