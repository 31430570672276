import Lottie from 'react-lottie';
import clickAndHold from '../../assets/pass/clickAndHold.png';
import attention from '../../assets/pass/attention.json';

import { boxTriggered } from './passFunctions.js';
import { useRef, useState, useEffect} from 'react';

import nftTitle from '../../assets/pass/nftTitle.png';
import nftLine from '../../assets/pass/nftLine.png';
import NftsImage from '../../assets/pass/clickAndHold/NFTs.png';

export function Nfts({isClickAndHold ,setIsClickAndHold}) {
  const holdThreshold = 100;
  const holdTimerRef = useRef(null);

  const [brightness, setBrightness] = useState(1);
  
  const handleMouseDown = (divName) => {
    holdTimerRef.current = setTimeout(() => {
      let currentBrightness = 1;
      const interval = setInterval(() => {
        currentBrightness += 0.1;
        setBrightness(currentBrightness);

        if (currentBrightness >= 1.5) {
          setIsClickAndHold(true);
          boxTriggered(true, divName, "attentionNft");
          clearInterval(interval);
        }
      }, 50);
    }, holdThreshold);
  };
  const handleMouseUp = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionNft");
    setBrightness(1);
  };
  const handleMouseLeave = (divName) => {
    clearTimeout(holdTimerRef.current);
    setIsClickAndHold(false);
    boxTriggered(false, divName, "attentionNft");
    setBrightness(1);
  };

  useEffect(() => {
    // Apply brightness filter to #tvBox
    const nftBoxElement = document.getElementById('clickHoldNft');
    nftBoxElement.style.filter = `brightness(${brightness})`;

    return () => {
        nftBoxElement.style.filter = 'none';
    };
  }, [brightness]);

  return (
    <>
      <div id='nftVisibility'>
        <div 
          id='nftBox'
          onMouseDown={()=>handleMouseDown("nftDiv")}
          onMouseUp={()=>handleMouseUp("nftDiv")}
          onMouseLeave={() => handleMouseLeave("nftDiv")}
        ></div>
        <img id='clickHoldNft' alt='clickHoldNft' src={clickAndHold}></img>
      </div>
      <div id='attentionNft'>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: attention,
          }}
          height={"10vw"}
          width={"7vw"}
        />
      </div>

      <div id='nftDiv'>
        <img src={nftTitle} alt='nftTitle' className='accessAcademyTitle'></img>
        <img src={nftLine} alt='nftLine' className='nftLine'></img>
        <p className='accessAcademyPara'>
        Chimba Pass holders benefit from<br></br>
        discounted NFT mints and exclusive NFT airdrops,<br></br>
        unlocking a world of digital collectibles and assets.<br></br>
        </p>
        <img src={NftsImage} alt='accessAcademyPH' className='accessAcademyPH'></img>
      </div>

    </>
  );
}